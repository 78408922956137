import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
// import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack, Box, TextField, Button } from '@material-ui/core';

// routes
// import { PATH_PAGE } from '../../routes/paths';
//
// import Logo from '.src/components/Logo';

// ----------------------------------------------------------------------

const SOCIALS = [
    { name: 'FaceBook', icon: facebookFill },
    { name: 'Google', icon: googleFill },
    // { name: 'Linkedin', icon: linkedinFill },
    { name: 'Twitter', icon: twitterFill }
];

const LINKS = [
    {
        headline: 'Categories',
        children: [
            { name: 'Arts', href: '#' },
            { name: 'Music', href: '#' },
            { name: 'Music', href: '#' },
            { name: 'Sports/Games', href: '#' },
            { name: 'Entertainment', href: '#' },
            { name: 'Virtual World', href: '#' },

        ]
    },
    {
        headline: 'Marketplace',
        children: [
            { name: 'Create', href: '#' },
            { name: 'Explore', href: '#' },
            { name: 'Creators', href: '#' },
            { name: 'Collectors', href: '#' }
        ]
    },
    {
        headline: 'More',
        children: [
            { name: 'Blog', href: '#' },
            { name: 'Careers', href: '#' },
            { name: 'Help Center', href: '#' },
            { name: 'FAQs', href: '#' }
        ]
    },
    {
        headline: 'Company',
        children: [
            { name: 'About us', href: '#' },
            { name: 'Privacy Policy', href: '#' },
            { name: 'Terms and Condition', href: '#' },
            { name: 'Community Guidlines', href: '#' },
            // { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' }
        ]
    }
];

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    marginTop:'20px'
}));


// ----------------------------------------------------------------------

export default function Footer() {
    return (
        <RootStyle>
            <Stack direction='row' sx={{alignItems:'center',justifyContent:'right',mb:2}}>
                <Typography variant='p' sx={{ fontWeight: '700', }}>Be Updated</Typography>
                <TextField size='small' label="Enter Your Email" sx={{ ml:1,mr:1,width:{md:400,sm:300,xs:200} }}/>
                <Button sx={{ borderRadius: "25px",mr:{sx:1,sm:3} }} variant="outlined" color="primary">Send</Button>
            </Stack>
            <Divider />
            <Container maxWidth="lg" sx={{ pt: 10 }}>
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                    <Grid item xs={12} sx={{ mb: 3 }}>
                        <ScrollLink to="move_top" spy smooth>
                            {/* <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} /> */}
                        </ScrollLink>
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <Typography variant="body2" sx={{ pr: { md: 5 } }}>
                            placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is.
                        </Typography>

                        <Typography component="p" variant="overline" sx={{ mt: 2, fontSize: 13 }}>
                            Join our community
                        </Typography>
                        <Stack
                            spacing={1.5}
                            direction="row"
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            sx={{ mt: 2, mb: { xs: 5, md: 0 } }}
                        >
                            {SOCIALS.map((social) => (
                                <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                                    <Icon icon={social.icon} width={16} height={16} />
                                </IconButton>
                            ))}
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
                            {LINKS.map((list) => {
                                const { headline, children } = list;
                                return (
                                    <Stack key={headline} spacing={2}>
                                        <Typography component="p" variant="overline" sx={{ fontSize: 13 }}>
                                            {headline}
                                        </Typography>
                                        {children.map((link) => (
                                            <Link
                                                to={link.href}
                                                key={link.name}
                                                color="inherit"
                                                variant="body2"
                                                component={RouterLink}
                                                sx={{ display: 'block' }}
                                            >
                                                {link.name}
                                            </Link>
                                        ))}
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            <Divider sx={{ mt: 2 }} />
        </RootStyle>
    );
}
