import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Link, Grid, Paper, Typography, CardActionArea, Avatar, Divider, Button, Stack, IconButton } from '@material-ui/core';
//
import { varFadeInUp, MotionInView } from 'src/components/animate';
import { AccessTime, ArrowRight } from '@material-ui/icons';
import Label from 'src/components/Label';
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
// ----------------------------------------------------------------------



const CARDS = [
    {
        icon: '/static/ape_art/cryptoart-nft-redefining-real-683x1024.jpg',
        name: 'The Lightning of Love',
        description: 'Easy to customize and extend each component, saving you time and money.'
    },

    {
        icon: '/static/ape_art/art-nfts-auction-2021-esther-barend.jpg',
        name: 'Beauty',
        description: 'Consistent design in colors, fonts ... makes brand recognition easy.'
    },
    {
        icon: '/static/ape_art/5NOWWNNEBRER7BXFZKCYRCA7NA.jpg',
        name: 'UI & UX Design',
        description:
            'The set is built on the principles of the atomic design system. It helps you to create projects fastest and easily customized packages for your projects.'
    },
    {
        icon: '/static/ape_art/Beeple-christies-NFT-art.jpg',
        name: 'UI & UX Design',
        description:
            'The set is built on the principles of the atomic design system. It helps you to create projects fastest and easily customized packages for your projects.'
    },

];


function ExploreMoreCard({ item, type }) {
    const { name, icon, } = item;
    const theme = useTheme()

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            {/* <MotionInView variants={varFadeInUp}> */}
            <Link component={RouterLink} to={`/item-details/${type}`} underline="none">
                <Paper
                    sx={{
                        p: 1,
                        position: 'relative',
                        boxShadow: (theme) => theme.customShadows.z8,
                        mb: 3,
                        '&:hover': { transform: 'scale(1.1)' },
                        transition: (theme) => theme.transitions.create('all')
                    }}
                >
                    {/* <CardActionArea
              sx={{
                p: 3,
                borderRadius: 1,
                color: 'primary.main',
                bgcolor: 'background.neutral'
              }}
            > */}
                    <Box
                        component="div"
                        src={icon}
                        alt={name}
                        sx={{
                            mx: 'auto',
                            backgroundImage: `url(${icon})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            height: '300px',
                            borderRadius: '10px'
                        }}
                    >
                        <Box component='div' sx={{ width: '100%', display: 'flex', alignItems: 'center', height: 40 }}>
                            <IconButton sx={{ ml: 'auto', background: alpha('#000', 0.15), padding: '4px' }}>
                                <ArrowRight sx={{ color: '#fff' }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Stack direction='row' sx={{ mt: 1, p: 1 }} justifyContent='space-between' >
                        <Typography variant="subtitle2" >
                            {name}
                        </Typography>
                        <Avatar alt='avatar' src='/static/avatars/3.png' sx={{ ml: 1, width: 25, height: 25 }} />
                    </Stack>
                    <Divider />
                    <Box component='div' sx={{ display: 'flex', mt: 1, ml: 1, mb: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="body2" sx={{ display: 'flex', ml: 1 }}>
                            Price
                        </Typography>
                        <Stack direction='row'>
                            <Typography variant="subtitle2">
                                5.55 WRX
                            </Typography>
                            <Typography variant="body2" sx={{ ml: 1 }}>(7.05 USD)</Typography>
                        </Stack>
                    </Box>
                </Paper>
            </Link>
            {/* </MotionInView> */}
        </Grid>
    );
}

export default function ExploreMore({ type }) {
    return (
        <Grid mt={3}>
            <Typography variant='h5' >Explore More</Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            <Grid container spacing={3}>
                {CARDS.map((CARD) => <ExploreMoreCard type={type} item={CARD} />)}
            </Grid>
        </Grid>
    )
}


