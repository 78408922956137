// ----------------------------------------------------------------------


export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        // containedGradient: {
        //   backgroundColor:'#fff',
        //   boxShadow: theme.customShadows.primary
        // },
        containedGrey: {
          // background: theme.palette.light.gradients.primary,
          // background:  alpha(theme.palette.button.grey, 0.7) ,
          // color: `${theme.palette.text.primary} !important`,
          // boxShadow: theme.customShadows.z24,
          // '&:hover': {
          //   background: theme.palette.button.grey,
          //   // background:  alpha(theme.palette.button.grey, 0.9),
          //   boxShadow: theme.shadows[10],
          // }
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
