// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Accordion, Box, Grid, Card, Container, Typography, Button, Stack, AccordionSummary, AccordionDetails, Divider, TextField, IconButton, Menu, MenuItem, Avatar, Tabs, Tab } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { AccessTimeRounded, ExpandLessRounded, MoreVertRounded, OpenInNew, Refresh, Share, } from '@material-ui/icons';
import ethereum from '@iconify/icons-logos/ethereum'
import walletIcon from '@iconify/icons-fa-solid/wallet';
import PriceHistory from '../components/item-details/graph/PriceAnalysis';
import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import Scrollbar from 'src/components/Scrollbar';
import PriceAnalysis from '../components/item-details/graph/PriceAnalysis';
import ExploreMore from 'src/components/item-details/ExploreMore';
import { useParams } from 'react-router';
import ItemDataTable from '../components/item-details/tables/ItemDataTable'




// ----------------------------------------------------------------------



const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(6)
    }
}));




const menuOptions = ['Share', 'Save', 'compare']

const More = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box component='div'>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ background: alpha('#000', 0.06) }}
            >
                <MoreVertRounded />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                        marginRight: 'auto'
                    },
                }}
            >
                {menuOptions.map((option) => (
                    <MenuItem key={option} >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

const OwnerCard = ({ data }) => {
    const { title, avatar, name } = data
    const theme = useTheme()
    return (
        <Stack sx={{ border: `1px solid ${theme.palette.grey[400]}`, borderRadius: '5px', p: .5, minWidth: 230, mr: 2 }}>
            <Stack spacing={2} direction='row'>
                <Avatar src={`/static/avatars/${avatar}.png`} />
                <Box component='div'>
                    <Typography color={theme.palette.grey[600]} variant='body2'>{title}</Typography>
                    <Typography variant='p'>{name}</Typography>
                </Box>
            </Stack>
        </Stack>
    )
}

const ownerDetail = [{
    name: 'Zain LIpshuz',
    title: 'Owner',
    avatar: 5
}, {
    name: 'Emorson Rosser',
    title: 'Creator',
    avatar: 3
},]

const OtherDetailTab = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, mb: 2, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Owners" value="1" />
                        <Tab label="Offers" value="2" />
                        <Tab label="Detail" value="3" />
                        <Tab label="History" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <OwnerCard data={ownerDetail[0]} />
                </TabPanel>
                <TabPanel value="2">
                    <ItemDataTable />
                </TabPanel>
                <TabPanel value="3">
                    <ItemDataTable />
                </TabPanel>
                <TabPanel value="4">
                    <ItemDataTable />
                </TabPanel>
            </TabContext>
        </Box>
    );
}


export default function ItemDetails() {
    const theme = useTheme()
    const { type } = useParams()

    return (
        <RootStyle>
            <Container  >
                <Card sx={{ p: 2 }}>
                    <Grid container >
                        <Grid xs={12} md={5} item>
                            <Box borderRadius='7px' sx={{ border: theme.palette.grey[300] + ' solid 1px' }}>
                                {/* <Stack direction='row' sx={{ justifyContent: 'space-between', ml: 1, mr: 1 }}>
                                    <Box component='div' sx={{ display: 'flex', alignItems: 'center', }} height={40}   >
                                        <MonetizationOn sx={{ color: theme.palette.grey[600], height: 20 }} />
                                        <LockOutlined sx={{ color: theme.palette.grey[600], height: 20 }} />
                                    </Box>
                                    <Box component='div' sx={{ display: 'flex', alignItems: 'center', }} height={40}   >
                                        <FavoriteBorder sx={{ color: theme.palette.grey[600], mr: 1, height: 20 }} />
                                        <Typography sx={{ color: theme.palette.grey[600], height: 20 }}>123</Typography>
                                    </Box>
                                </Stack> */}
                                <Box component='img' src='/static/ape_art/Beeple-christies-NFT-art.jpg' sx={{ minHeight: 490, objectFit: 'cover' }} borderRadius=' 7px' />
                            </Box>
                        </Grid>
                        <Grid md={7} item maxHeight={490} xs={12} overflow="auto" pl={2}  >
                            <Scrollbar >
                                <Stack direction='row' mt={1} mb={1} mr={1} justifyContent='space-between' >
                                    {/* <Typography component="p" fontSize={16} fontWeight={600} variant="overline" color={theme.palette.primary.main}>Lionel Messi</Typography>
                                <Verified sx={{ ml: 1, color: theme.palette.primary.main }} />
                                <ButtonGroup size='medium' sx={{ ml: 'auto' }}  >
                                    {buttonGroupItems.map((icon) => <Button sx={{ '&.MuiButtonGroup-grouped': { borderColor: ` ${theme.palette.grey[500]} !important` }, pt: 1, pb: 1, color: theme.palette.grey[600] }} >{icon}</Button>)}
                                </ButtonGroup> */}
                                    <Typography variant="h4" color={theme.palette.grey[900]} >The Lightning of Love</Typography>
                                    <More />
                                </Stack>
                                <Typography variant="p" color={theme.palette.grey[600]} mt={2} >
                                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lo
                                </Typography>
                                <Divider sx={{ mt: 3, mb: 3 }} />
                                <Stack direction='row' mb={3}>
                                    {ownerDetail.map((obj) => <OwnerCard data={obj} />)}
                                </Stack>
                                <Typography variant="p" fontSize={16} fontWeight={400} color={theme.palette.grey[600]} mr={1} >Price </Typography><br />
                                <Stack direction='row' alignItems={'center'} spacing={2} mb={3} >
                                    <Typography variant="h6" color={theme.palette.grey[800]} >0.55 WRX </Typography>
                                    <Typography variant="p" color={theme.palette.grey[600]} fontSize={15}>(7.05 USD)</Typography>
                                </Stack>
                                <Stack spacing={2} mr={2} direction='row' mb={2} >
                                    {type !== 'bid' && < Button sx={{ borderRadius: 10, width: '100%' }} size='large' variant='outlined'>Make Offer</Button>}
                                    <Button sx={{ borderRadius: 10, width: '100%' }} size='large' variant='contained'>{type === 'bid' ? 'Place Bid' : 'Buy Now'}</Button>
                                </Stack>
                                <OtherDetailTab />
                                {/* <Card sx={{ mb: 2, minHeight: 100, boxShadow: 'none', border: `1px solid ${theme.palette.action.focus}`, }}>
                                <Stack direction='row' height={60} alignItems='center' color={theme.palette.grey[600]}>
                                    <AccessTimeRounded sx={{ ml: 2, mr: 1 }} />
                                    <Typography>Sale ends August 3, 2022 at 9:30pm IST </Typography>
                                </Stack>
                                <Divider variant="fullWidth" />

                                <Grid minHeight={80} p={2}>
                                    <Stack maxWidth={450}>
                                    </Stack>
                                </Grid>
                            </Card> */}

                                {/* <Accordion>
                                <AccordionSummary expandIcon={<ExpandLessRounded />}>
                                    <Typography variant="subtitle1">HEllO efk</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <PriceHistory />
                                </AccordionDetails>
                            </Accordion> */}
                            </Scrollbar>
                        </Grid>
                    </Grid>
                </Card>
                <PriceAnalysis />
                <ExploreMore type={type} />
            </Container >
        </RootStyle >
    )
}


// ---------------------------------------------------------------------
