import { Table, TableRow, TableHead, TableBody, TableCell, TableContainer } from '@material-ui/core';
import Scrollbar from 'src/components/Scrollbar';
// components
// import Scrollbar from '../../../../components/Scrollbar';

// ----------------------------------------------------------------------

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const BASIC_TABLE = [
  createData('Frozen yoghurt', 159, 6.0, 24),
  createData('Ice cream sandwich', 237, 9.0, 37),
  createData('Eclair', 262, 16.0, 24),
  createData('Cupcake', 305, 3.7, 67),
  createData('Gingerbread', 356, 16.0, 49)
];

// ----------------------------------------------------------------------

export default function ItemDataTable() {
  return (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 600, mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Price</TableCell>
              <TableCell align="right">USD</TableCell>
              <TableCell align="right">Expiration</TableCell>
              <TableCell align="right">From</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {BASIC_TABLE.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}