import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2503.069,2512.906 2503.069,2510.826 2500.989,2512.906 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2500.989,2512.906 2501.322,2510.853 2498.936,2512.573 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2498.935,2512.573 2499.594,2510.6 2496.962,2511.914 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.962,2511.914 2497.928,2510.072 2495.12,2510.947 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2495.12,2510.947 2496.369,2509.284 2493.457,2509.697 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.457,2509.697 2494.957,2508.256 2492.016,2508.197 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2492.016,2508.197 2493.727,2507.015 2490.834,2506.485 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.834,2506.484 2492.713,2505.593 2489.942,2504.605 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.942,2504.605 2491.94,2504.026 2489.363,2502.607 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.363,2502.606 2491.428,2502.356 2489.113,2500.542 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.112,2500.541 2491.191,2500.625 2489.196,2498.463 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.196,2498.463 2491.234,2498.879 2489.612,2496.425 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.612,2496.424 2491.557,2497.162 2490.35,2494.479 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.35,2494.479 2492.151,2495.519 2491.39,2492.678 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2491.39,2492.677 2493.001,2493.993 2492.706,2491.066 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2492.706,2491.066 2494.085,2492.623 2494.263,2489.687 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2494.263,2489.686 2495.375,2491.444 2496.021,2488.575 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.022,2488.574 2496.837,2490.488 2497.935,2487.759 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2497.936,2487.759 2498.433,2489.779 2499.955,2487.261 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2499.956,2487.261 2500.123,2489.334 2502.029,2487.094 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.029,2487.094 2501.862,2489.167 2504.103,2487.261 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2504.103,2487.261 2503.605,2489.281 2506.123,2487.759 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2506.123,2487.759 2505.308,2489.673 2508.036,2488.574 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.037,2488.575 2506.925,2490.333 2509.795,2489.686 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2509.795,2489.687 2508.416,2491.243 2511.352,2491.066 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2511.352,2491.066 2509.741,2492.382 2512.668,2492.677 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2512.668,2492.678 2510.867,2493.718 2513.708,2494.479 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2513.708,2494.479 2511.763,2495.217 2514.446,2496.424 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.446,2496.425 2512.408,2496.841 2514.862,2498.462 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.862,2498.463 2512.784,2498.547 2514.946,2500.541 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.946,2500.542 2512.881,2500.291 2514.695,2502.606 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.695,2502.607 2512.697,2502.028 2514.116,2504.605 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.116,2504.605 2512.237,2503.714 2513.224,2506.484 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2513.224,2506.485 2511.512,2505.303 2512.043,2508.197 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2512.042,2508.197 2510.542,2506.756 2510.602,2509.697 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.601,2509.697 2509.352,2508.034 2508.938,2510.947 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.938,2510.947 2507.971,2509.105 2507.096,2511.914 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2507.096,2511.914 2506.437,2509.941 2505.123,2512.573 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2505.123,2512.573 2504.789,2510.52 2503.07,2512.906 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2501.031,2515.4 2500.992,2512.922 2503.51,2515.362 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2503.511,2515.361 2503.075,2512.921 2505.951,2514.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2505.952,2514.925 2505.13,2512.586 2508.291,2514.103 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.291,2514.103 2507.104,2511.926 2510.468,2512.917 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.468,2512.917 2508.948,2510.958 2512.427,2511.396 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2512.427,2511.396 2510.612,2509.707 2514.116,2509.581 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.116,2509.581 2512.054,2508.205 2515.492,2507.519 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2515.493,2507.518 2513.236,2506.491 2516.52,2505.262 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2516.52,2505.261 2514.128,2504.609 2517.172,2502.87 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.173,2502.869 2514.707,2502.609 2517.433,2500.404 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.433,2500.403 2514.958,2500.542 2517.294,2497.928 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.294,2497.927 2514.873,2498.461 2516.76,2495.507 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2516.76,2495.506 2514.456,2496.421 2515.844,2493.202 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2515.844,2493.202 2513.717,2494.475 2514.571,2491.074 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.571,2491.074 2512.675,2492.672 2512.973,2489.178 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2512.972,2489.178 2511.358,2491.059 2511.091,2487.563 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2511.091,2487.563 2509.799,2489.679 2508.975,2486.271 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.974,2486.271 2508.039,2488.567 2506.679,2485.335 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2506.678,2485.335 2506.123,2487.751 2504.262,2484.78 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2504.262,2484.78 2504.101,2487.254 2501.788,2484.619 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2501.787,2484.619 2502.025,2487.087 2499.32,2484.857 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2499.319,2484.857 2499.95,2487.255 2496.922,2485.489 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.921,2485.489 2497.928,2487.754 2494.656,2486.496 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2494.656,2486.496 2496.013,2488.571 2492.581,2487.854 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2492.581,2487.854 2494.254,2489.684 2490.751,2489.527 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.751,2489.528 2492.696,2491.065 2489.213,2491.472 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.213,2491.473 2491.379,2492.678 2488.007,2493.639 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2488.007,2493.639 2490.339,2494.482 2487.165,2495.971 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.165,2495.971 2489.601,2496.429 2486.707,2498.408 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.707,2498.408 2489.185,2498.469 2486.646,2500.887 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.646,2500.887 2489.102,2500.55 2486.983,2503.344 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.983,2503.344 2489.354,2502.617 2487.71,2505.714 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.711,2505.715 2489.933,2504.617 2488.808,2507.937 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2488.809,2507.938 2490.827,2506.498 2490.249,2509.956 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.249,2509.956 2492.01,2508.211 2491.994,2511.717 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2491.995,2511.717 2493.453,2509.712 2494,2513.175 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2494,2513.175 2495.118,2510.962 2496.213,2514.293 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.214,2514.293 2496.962,2511.929 2498.577,2515.041 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2498.578,2515.041 2498.937,2512.588 2501.031,2515.4 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2503.507,2518.335 2503.507,2515.38 2500.552,2518.335 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2500.551,2518.335 2501.025,2515.418 2497.635,2517.861 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2497.634,2517.861 2498.57,2515.058 2494.831,2516.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2494.831,2516.925 2496.204,2514.308 2492.214,2515.552 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2492.214,2515.551 2493.989,2513.189 2489.851,2513.776 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.851,2513.776 2491.982,2511.729 2487.804,2511.645 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.804,2511.644 2490.235,2509.966 2486.125,2509.212 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.125,2509.212 2488.794,2507.945 2484.858,2506.542 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.858,2506.542 2487.696,2505.719 2484.036,2503.703 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.036,2503.703 2486.969,2503.347 2483.679,2500.77 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2483.679,2500.769 2486.632,2500.888 2483.798,2497.816 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2483.799,2497.816 2486.694,2498.407 2484.39,2494.921 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.39,2494.92 2487.153,2495.968 2485.438,2492.157 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2485.438,2492.157 2487.997,2493.634 2486.915,2489.598 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.916,2489.597 2489.204,2491.466 2488.784,2487.308 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2488.785,2487.308 2490.744,2489.52 2490.997,2485.349 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.997,2485.348 2492.576,2487.845 2493.494,2483.769 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.495,2483.769 2494.653,2486.487 2496.213,2482.61 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.214,2482.61 2496.921,2485.479 2499.083,2481.903 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2499.083,2481.903 2499.321,2484.848 2502.029,2481.665 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.03,2481.665 2501.792,2484.61 2504.975,2481.903 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2504.975,2481.903 2504.268,2484.772 2507.844,2482.61 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2507.845,2482.61 2506.687,2485.329 2510.563,2483.769 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.564,2483.769 2508.985,2486.266 2513.061,2485.348 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2513.062,2485.349 2511.102,2487.56 2515.273,2487.308 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2515.274,2487.308 2512.985,2489.177 2517.143,2489.597 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.143,2489.598 2514.584,2491.075 2518.62,2492.157 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2518.621,2492.157 2515.858,2493.205 2519.668,2494.92 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.669,2494.921 2516.773,2495.512 2520.26,2497.816 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.26,2497.816 2517.307,2497.936 2520.379,2500.769 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.379,2500.77 2517.446,2500.413 2520.022,2503.703 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.022,2503.703 2517.184,2502.881 2519.2,2506.542 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.2,2506.542 2516.531,2505.276 2517.933,2509.212 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.933,2509.212 2515.501,2507.534 2516.255,2511.644 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2516.254,2511.645 2514.123,2509.598 2514.207,2513.776 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.207,2513.776 2512.432,2511.414 2511.844,2515.551 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2511.844,2515.552 2510.471,2512.935 2509.228,2516.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2509.227,2516.925 2508.291,2514.122 2506.424,2517.861 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2506.424,2517.861 2505.95,2514.944 2503.507,2518.335 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2500.612,2521.878 2500.556,2518.357 2504.133,2521.823 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2504.134,2521.823 2503.514,2518.356 2507.601,2521.203 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2507.602,2521.203 2506.434,2517.88 2510.924,2520.035 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.925,2520.035 2509.24,2516.943 2514.017,2518.35 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.018,2518.349 2511.858,2515.567 2516.8,2516.189 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2516.8,2516.189 2514.222,2513.79 2519.2,2513.611 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.2,2513.61 2516.271,2511.656 2521.155,2510.681 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2521.156,2510.68 2517.95,2509.221 2522.615,2507.475 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2522.615,2507.474 2519.218,2506.548 2523.542,2504.077 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2523.542,2504.076 2520.04,2503.706 2523.912,2500.573 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2523.912,2500.573 2520.395,2500.77 2523.715,2497.056 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2523.714,2497.056 2520.275,2497.814 2522.956,2493.617 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2522.956,2493.616 2519.683,2494.916 2521.655,2490.343 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2521.655,2490.342 2518.633,2492.151 2519.846,2487.32 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.846,2487.32 2517.153,2489.59 2517.576,2484.627 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.575,2484.626 2515.282,2487.299 2514.903,2482.333 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.902,2482.332 2513.067,2485.338 2511.896,2480.497 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2511.896,2480.497 2510.566,2483.758 2508.635,2479.167 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.634,2479.167 2507.845,2482.599 2505.201,2478.378 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2505.201,2478.378 2504.972,2481.892 2501.686,2478.15 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2501.686,2478.15 2502.024,2481.655 2498.18,2478.488 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2498.179,2478.488 2499.076,2481.894 2494.773,2479.385 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2494.773,2479.385 2496.204,2482.603 2491.555,2480.816 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2491.554,2480.816 2493.483,2483.763 2488.607,2482.745 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2488.607,2482.746 2490.983,2485.345 2486.008,2485.122 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.007,2485.123 2488.77,2487.307 2483.823,2487.885 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2483.823,2487.886 2486.9,2489.599 2482.11,2490.963 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2482.109,2490.964 2485.421,2492.161 2480.912,2494.276 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2480.912,2494.277 2484.374,2494.927 2480.262,2497.738 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2480.262,2497.739 2483.783,2497.826 2480.175,2501.26 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2480.175,2501.261 2483.665,2500.781 2480.655,2504.75 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2480.655,2504.75 2484.022,2503.718 2481.688,2508.117 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2481.688,2508.118 2484.846,2506.559 2483.248,2511.276 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2483.248,2511.276 2486.115,2509.23 2485.294,2514.143 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2485.294,2514.144 2487.796,2511.665 2487.774,2516.645 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.774,2516.646 2489.845,2513.797 2490.623,2518.717 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.623,2518.717 2492.211,2515.573 2493.767,2520.305 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.768,2520.305 2494.831,2516.947 2497.125,2521.368 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2497.126,2521.368 2497.636,2517.883 2500.611,2521.878 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2504.128,2526.047 2504.128,2521.849 2499.93,2526.047 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2499.929,2526.047 2500.603,2521.903 2495.786,2525.373 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2495.785,2525.373 2497.115,2521.391 2491.803,2524.044 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2491.803,2524.043 2493.753,2520.326 2488.086,2522.093 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2488.085,2522.092 2490.607,2518.736 2484.729,2519.57 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.729,2519.57 2487.756,2516.662 2481.821,2516.542 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2481.82,2516.542 2485.275,2514.157 2479.436,2513.087 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2479.435,2513.086 2483.228,2511.287 2477.636,2509.294 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2477.635,2509.293 2481.667,2508.125 2476.468,2505.261 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2476.467,2505.26 2480.634,2504.754 2475.961,2501.093 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2475.961,2501.092 2480.156,2501.261 2476.13,2496.898 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2476.131,2496.897 2480.243,2497.737 2476.97,2492.784 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2476.97,2492.783 2480.895,2494.272 2478.459,2488.859 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2478.459,2488.858 2482.094,2490.957 2480.558,2485.222 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2480.559,2485.222 2483.81,2487.876 2483.213,2481.97 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2483.214,2481.969 2485.998,2485.112 2486.356,2479.186 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.357,2479.186 2488.6,2482.733 2489.905,2476.942 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.906,2476.941 2491.551,2480.803 2493.767,2475.296 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.768,2475.296 2494.773,2479.372 2497.844,2474.291 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2497.845,2474.291 2498.182,2478.475 2502.029,2473.953 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.03,2473.953 2501.692,2478.137 2506.214,2474.291 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2506.215,2474.291 2505.21,2478.367 2510.29,2475.296 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.291,2475.296 2508.646,2479.158 2514.153,2476.941 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.154,2476.942 2511.91,2480.49 2517.701,2479.186 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2517.702,2479.186 2514.919,2482.328 2520.844,2481.969 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.845,2481.97 2517.593,2484.625 2523.5,2485.222 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2523.5,2485.222 2519.865,2487.321 2525.599,2488.858 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2525.599,2488.859 2521.675,2490.347 2527.088,2492.783 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2527.088,2492.784 2522.975,2493.624 2527.928,2496.897 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2527.928,2496.898 2523.734,2497.067 2528.097,2501.092 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2528.097,2501.093 2523.93,2500.587 2527.591,2505.26 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2527.591,2505.261 2523.559,2504.093 2526.423,2509.293 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.422,2509.294 2522.63,2507.494 2524.623,2513.086 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2524.623,2513.087 2521.168,2510.703 2522.238,2516.542 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2522.237,2516.542 2519.21,2513.635 2519.33,2519.57 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.329,2519.57 2516.807,2516.215 2515.973,2522.092 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2515.972,2522.093 2514.021,2518.376 2512.256,2524.043 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2512.255,2524.044 2510.925,2520.062 2508.273,2525.373 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.272,2525.373 2507.599,2521.23 2504.129,2526.047 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2500.015,2531.08 2499.937,2526.078 2505.018,2531.002 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2505.019,2531.001 2504.139,2526.076 2509.944,2530.122 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2509.945,2530.121 2508.287,2525.401 2514.666,2528.463 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.667,2528.462 2512.272,2524.069 2519.06,2526.068 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.061,2526.067 2515.992,2522.115 2523.012,2522.999 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2523.013,2522.998 2519.351,2519.59 2526.422,2519.336 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.423,2519.335 2522.261,2516.558 2529.2,2515.174 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2529.2,2515.173 2524.647,2513.099 2531.274,2510.619 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2531.274,2510.618 2526.447,2509.302 2532.591,2505.792 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2532.591,2505.79 2527.615,2505.265 2533.116,2500.815 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2533.116,2500.814 2528.12,2501.094 2532.836,2495.818 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2532.836,2495.817 2527.95,2496.895 2531.758,2490.932 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2531.758,2490.931 2527.108,2492.778 2529.91,2486.281 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2529.91,2486.28 2525.617,2488.849 2527.341,2481.987 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2527.34,2481.986 2523.515,2485.211 2524.115,2478.161 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2524.114,2478.16 2520.856,2481.957 2520.318,2474.902 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.317,2474.901 2517.71,2479.171 2516.047,2472.294 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2516.046,2472.293 2514.157,2476.926 2511.413,2470.405 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2511.412,2470.404 2510.291,2475.28 2506.536,2469.283 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2506.535,2469.283 2506.21,2474.276 2501.542,2468.959 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2501.541,2468.959 2502.022,2473.939 2496.561,2469.44 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.56,2469.44 2497.833,2474.278 2491.721,2470.714 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2491.72,2470.714 2493.754,2475.285 2487.149,2472.747 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.148,2472.748 2489.888,2476.934 2482.962,2475.488 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2482.961,2475.488 2486.337,2479.181 2479.269,2478.865 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2479.268,2478.865 2483.193,2481.968 2476.165,2482.79 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2476.165,2482.791 2480.536,2485.224 2473.731,2487.163 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2473.731,2487.164 2478.436,2488.864 2472.03,2491.869 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2472.03,2491.87 2476.947,2492.793 2471.106,2496.787 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2471.106,2496.788 2476.108,2496.911 2470.983,2501.79 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2470.983,2501.791 2475.94,2501.11 2471.665,2506.748 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2471.665,2506.749 2476.448,2505.281 2473.132,2511.532 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2473.132,2511.533 2477.618,2509.317 2475.348,2516.019 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2475.348,2516.02 2479.421,2513.113 2478.255,2520.092 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2478.256,2520.093 2481.809,2516.571 2481.778,2523.646 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2481.779,2523.647 2484.721,2519.6 2485.825,2526.589 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2485.826,2526.59 2488.081,2522.124 2490.292,2528.845 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.293,2528.845 2491.802,2524.075 2495.063,2530.355 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2495.064,2530.355 2495.789,2525.405 2500.014,2531.08 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2505.011,2537.002 2505.011,2531.039 2499.048,2537.002 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2499.046,2537.002 2500.003,2531.116 2493.16,2536.045 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.159,2536.045 2495.047,2530.389 2487.502,2534.157 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.501,2534.156 2490.273,2528.876 2482.221,2531.385 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2482.22,2531.384 2485.802,2526.617 2477.453,2527.802 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2477.452,2527.801 2481.752,2523.67 2473.321,2523.5 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2473.32,2523.499 2478.228,2520.112 2469.933,2518.592 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2469.932,2518.591 2475.319,2516.034 2467.375,2513.203 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2467.375,2513.202 2473.103,2511.543 2465.716,2507.474 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2465.716,2507.473 2471.635,2506.754 2464.997,2501.553 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2464.997,2501.552 2470.955,2501.792 2465.237,2495.593 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2465.237,2495.592 2471.08,2496.785 2466.43,2489.749 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2466.43,2489.748 2472.006,2491.863 2468.545,2484.172 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2468.545,2484.171 2473.71,2487.153 2471.527,2479.007 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2471.528,2479.006 2476.147,2482.777 2475.299,2474.387 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2475.3,2474.386 2479.255,2478.849 2479.764,2470.431 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2479.765,2470.43 2482.952,2475.47 2484.805,2467.243 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.806,2467.243 2487.143,2472.729 2490.292,2464.906 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.293,2464.905 2491.72,2470.695 2496.083,2463.478 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.084,2463.478 2496.564,2469.422 2502.028,2462.998 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.03,2462.998 2501.55,2468.942 2507.974,2463.478 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2507.975,2463.478 2506.548,2469.268 2513.765,2464.905 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2513.766,2464.906 2511.429,2470.391 2519.252,2467.243 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.253,2467.243 2516.066,2472.283 2524.293,2470.431 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2524.294,2470.431 2520.34,2474.895 2528.758,2474.386 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2528.759,2474.387 2524.14,2478.158 2532.53,2479.006 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2532.531,2479.007 2527.367,2481.989 2535.513,2484.171 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2535.513,2484.172 2529.938,2486.287 2537.628,2489.748 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2537.628,2489.749 2531.785,2490.942 2538.821,2495.592 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2538.821,2495.593 2532.863,2495.833 2539.061,2501.552 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2539.061,2501.553 2533.142,2500.834 2538.343,2507.473 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2538.342,2507.474 2532.615,2505.815 2536.683,2513.202 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.683,2513.203 2531.295,2510.647 2534.126,2518.591 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2534.125,2518.592 2529.218,2515.204 2530.738,2523.499 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2530.737,2523.5 2526.437,2519.37 2526.606,2527.801 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.605,2527.802 2523.023,2523.035 2521.838,2531.384 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2521.837,2531.385 2519.066,2526.105 2516.557,2534.156 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2516.556,2534.157 2514.667,2528.5 2510.899,2536.045 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.898,2536.045 2509.941,2530.159 2505.012,2537.002 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2499.168,2544.153 2499.057,2537.046 2506.275,2544.042 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2506.277,2544.041 2505.027,2537.045 2513.273,2542.791 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2513.275,2542.791 2510.919,2536.085 2519.98,2540.434 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.982,2540.434 2516.581,2534.193 2526.223,2537.032 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.224,2537.031 2521.865,2531.417 2531.838,2532.673 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2531.84,2532.672 2526.637,2527.829 2536.682,2527.469 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.683,2527.468 2530.771,2523.523 2540.628,2521.556 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2540.629,2521.554 2534.161,2518.609 2543.575,2515.086 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2543.575,2515.084 2536.718,2513.215 2545.445,2508.228 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2545.445,2508.226 2538.377,2507.48 2546.191,2501.157 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2546.191,2501.156 2539.095,2501.554 2545.793,2494.06 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2545.793,2494.058 2538.853,2495.589 2544.262,2487.117 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2544.262,2487.116 2537.657,2489.74 2541.637,2480.51 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2541.637,2480.509 2535.538,2484.159 2537.987,2474.41 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2537.986,2474.409 2532.552,2478.99 2533.405,2468.975 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2533.404,2468.974 2528.775,2474.367 2528.01,2464.345 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2528.009,2464.344 2524.305,2470.41 2521.943,2460.64 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2521.941,2460.639 2519.259,2467.221 2515.36,2457.956 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2515.358,2457.956 2513.766,2464.883 2508.431,2456.364 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.43,2456.363 2507.969,2463.456 2501.337,2455.903 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2501.336,2455.903 2502.019,2462.977 2494.261,2456.586 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2494.259,2456.586 2496.069,2463.459 2487.386,2458.395 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.385,2458.396 2490.273,2464.89 2480.89,2461.284 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2480.889,2461.285 2484.781,2467.232 2474.942,2465.177 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2474.941,2465.178 2479.737,2470.424 2469.696,2469.975 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2469.694,2469.976 2475.27,2474.384 2465.286,2475.551 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2465.285,2475.552 2471.496,2479.009 2461.829,2481.763 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2461.828,2481.764 2468.512,2484.18 2459.412,2488.449 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2459.412,2488.45 2466.397,2489.762 2458.1,2495.436 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2458.1,2495.437 2465.205,2495.612 2457.925,2502.542 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2457.925,2502.544 2464.967,2501.577 2458.893,2509.585 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2458.893,2509.587 2465.688,2507.503 2460.978,2516.382 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2460.978,2516.384 2467.351,2513.236 2464.125,2522.756 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2464.126,2522.758 2469.912,2518.629 2468.255,2528.543 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2468.256,2528.544 2473.304,2523.541 2473.26,2533.592 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2473.261,2533.593 2477.441,2527.845 2479.009,2537.773 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2479.011,2537.774 2482.214,2531.429 2485.355,2540.978 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2485.357,2540.978 2487.501,2534.202 2492.133,2543.123 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2492.135,2543.123 2493.164,2536.091 2499.167,2544.153 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2506.265,2552.566 2506.265,2544.094 2497.793,2552.566 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2497.792,2552.566 2499.15,2544.204 2489.43,2551.207 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.428,2551.206 2492.111,2543.171 2481.393,2548.524 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2481.391,2548.523 2485.328,2541.022 2473.89,2544.586 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2473.888,2544.585 2478.977,2537.813 2467.116,2539.496 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2467.114,2539.495 2473.224,2533.626 2461.246,2533.385 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2461.245,2533.384 2468.217,2528.571 2456.432,2526.412 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2456.431,2526.41 2464.085,2522.779 2452.8,2518.757 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2452.799,2518.755 2460.936,2516.398 2450.442,2510.618 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2450.442,2510.616 2458.852,2509.595 2449.421,2502.206 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2449.421,2502.204 2457.885,2502.546 2449.762,2493.74 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2449.762,2493.738 2458.062,2495.433 2451.457,2485.438 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2451.457,2485.436 2459.378,2488.44 2454.461,2477.515 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2454.462,2477.513 2461.798,2481.749 2458.698,2470.177 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2458.699,2470.175 2465.261,2475.533 2464.056,2463.613 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2464.058,2463.612 2469.675,2469.953 2470.399,2457.994 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2470.4,2457.993 2474.928,2465.153 2477.56,2453.466 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2477.562,2453.465 2480.882,2461.258 2485.355,2450.144 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2485.357,2450.144 2487.385,2458.369 2493.582,2448.116 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.584,2448.116 2494.266,2456.56 2502.028,2447.434 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.03,2447.434 2501.348,2455.878 2510.474,2448.116 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.476,2448.116 2508.448,2456.342 2518.701,2450.144 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2518.703,2450.144 2515.382,2457.938 2526.496,2453.465 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.498,2453.466 2521.97,2460.625 2533.658,2457.993 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2533.66,2457.994 2528.042,2464.335 2540,2463.612 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2540.002,2463.613 2533.44,2468.971 2545.36,2470.175 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2545.361,2470.177 2538.024,2474.413 2549.596,2477.513 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2549.597,2477.515 2541.676,2480.519 2552.601,2485.436 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2552.602,2485.438 2544.302,2487.132 2554.296,2493.738 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2554.296,2493.74 2545.832,2494.081 2554.637,2502.204 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2554.637,2502.206 2546.228,2501.185 2553.616,2510.616 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2553.616,2510.618 2545.479,2508.261 2551.259,2518.755 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2551.259,2518.757 2543.605,2515.125 2547.627,2526.41 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2547.626,2526.412 2540.654,2521.599 2542.813,2533.384 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2542.812,2533.385 2536.703,2527.517 2536.944,2539.495 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.943,2539.496 2531.853,2532.723 2530.17,2544.585 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2530.168,2544.586 2526.232,2537.085 2522.667,2548.523 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2522.666,2548.524 2519.983,2540.488 2514.63,2551.206 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.628,2551.207 2513.27,2542.845 2506.267,2552.566 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2497.965,2562.724 2497.807,2552.629 2508.061,2562.566 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2508.063,2562.566 2506.287,2552.626 2518.003,2560.79 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2518.005,2560.789 2514.658,2551.263 2527.531,2557.442 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2527.533,2557.441 2522.701,2548.575 2536.399,2552.608 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.401,2552.607 2530.209,2544.632 2544.376,2546.416 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2544.378,2546.414 2536.987,2539.535 2551.258,2539.023 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2551.259,2539.021 2542.86,2533.417 2556.863,2530.622 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2556.865,2530.621 2547.676,2526.436 2561.049,2521.431 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2561.05,2521.429 2551.309,2518.773 2563.707,2511.688 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2563.707,2511.686 2553.666,2510.626 2564.766,2501.645 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2564.766,2501.642 2554.685,2502.207 2564.201,2491.561 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2564.201,2491.559 2554.341,2493.734 2562.026,2481.699 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2562.026,2481.697 2552.642,2485.425 2558.297,2472.313 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2558.296,2472.311 2549.632,2477.496 2553.111,2463.647 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2553.11,2463.645 2545.39,2470.153 2546.602,2455.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2546.6,2455.924 2540.024,2463.586 2538.938,2449.348 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2538.937,2449.346 2533.675,2457.964 2530.319,2444.084 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2530.317,2444.083 2526.506,2453.434 2520.967,2440.272 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.965,2440.271 2518.702,2450.112 2511.124,2438.01 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2511.122,2438.009 2510.468,2448.085 2501.046,2437.355 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2501.044,2437.355 2502.014,2447.405 2490.994,2438.325 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.991,2438.326 2493.562,2448.09 2481.227,2440.896 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2481.225,2440.896 2485.328,2450.122 2471.999,2445 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2471.997,2445.001 2477.527,2453.449 2463.549,2450.531 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2463.547,2450.532 2470.361,2457.984 2456.095,2457.345 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2456.094,2457.347 2464.014,2463.609 2449.832,2465.268 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2449.831,2465.27 2458.653,2470.18 2444.92,2474.092 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2444.919,2474.094 2454.415,2477.526 2441.487,2483.59 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2441.487,2483.592 2451.41,2485.456 2439.623,2493.516 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2439.622,2493.518 2449.716,2493.766 2439.374,2503.612 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2439.375,2503.614 2449.377,2502.24 2440.749,2513.617 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2440.749,2513.62 2450.402,2510.658 2443.71,2523.273 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2443.711,2523.275 2452.764,2518.803 2448.183,2532.328 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2448.184,2532.33 2456.402,2526.464 2454.049,2540.548 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2454.051,2540.55 2461.222,2533.442 2461.159,2547.721 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2461.161,2547.723 2467.099,2539.556 2469.327,2553.661 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2469.329,2553.662 2473.88,2544.649 2478.342,2558.213 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2478.344,2558.214 2481.391,2548.588 2487.97,2561.261 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2487.973,2561.261 2489.436,2551.271 2497.963,2562.724 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2508.046,2574.676 2508.046,2562.641 2496.012,2574.676 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.009,2574.676 2497.94,2562.797 2484.13,2572.745 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.128,2572.744 2487.939,2561.329 2472.712,2568.933 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2472.71,2568.932 2478.303,2558.276 2462.054,2563.34 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2462.052,2563.338 2469.281,2553.717 2452.431,2556.108 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2452.428,2556.106 2461.108,2547.77 2444.092,2547.427 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2444.09,2547.425 2453.994,2540.589 2437.254,2537.521 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2437.252,2537.519 2448.125,2532.359 2432.093,2526.646 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2432.092,2526.643 2443.652,2523.295 2428.744,2515.084 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2428.743,2515.081 2440.69,2513.631 2427.293,2503.134 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2427.293,2503.132 2439.318,2503.616 2427.777,2491.107 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2427.778,2491.104 2439.569,2493.511 2430.185,2479.313 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2430.186,2479.31 2441.438,2483.578 2434.453,2468.058 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2434.454,2468.055 2444.877,2474.073 2440.472,2457.633 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2440.473,2457.631 2449.795,2465.242 2448.084,2448.309 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2448.086,2448.307 2456.067,2457.315 2457.094,2440.326 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2457.096,2440.324 2463.529,2450.496 2467.268,2433.893 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2467.271,2433.891 2471.988,2444.963 2478.342,2429.174 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2478.344,2429.173 2481.225,2440.858 2490.03,2426.293 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.032,2426.293 2491,2438.288 2502.028,2425.324 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.031,2425.324 2501.062,2437.32 2514.026,2426.293 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.029,2426.293 2511.149,2437.978 2525.714,2429.173 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2525.716,2429.174 2520.999,2440.246 2536.788,2433.891 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.79,2433.893 2530.358,2444.064 2546.962,2440.324 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2546.964,2440.326 2538.983,2449.334 2555.972,2448.307 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2555.974,2448.309 2546.652,2455.92 2563.585,2457.631 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2563.586,2457.633 2553.164,2463.65 2569.604,2468.055 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2569.605,2468.058 2558.353,2472.325 2573.873,2479.31 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2573.873,2479.313 2562.082,2481.72 2576.281,2491.104 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2576.281,2491.107 2564.256,2491.591 2576.765,2503.132 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2576.765,2503.134 2564.818,2501.684 2575.315,2515.081 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2575.314,2515.084 2563.755,2511.736 2571.966,2526.643 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2571.965,2526.646 2561.092,2521.487 2566.806,2537.519 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2566.804,2537.521 2556.9,2530.684 2559.968,2547.425 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2559.966,2547.427 2551.287,2539.091 2551.63,2556.107 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2551.628,2556.108 2544.398,2546.487 2542.007,2563.338 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2542.004,2563.34 2536.412,2552.683 2531.348,2568.932 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2531.346,2568.933 2527.535,2557.518 2519.93,2572.744 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.928,2572.745 2517.997,2560.866 2508.049,2574.676 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2496.256,2589.107 2496.031,2574.765 2510.598,2588.882 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2510.602,2588.882 2508.078,2574.761 2524.722,2586.359 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2524.725,2586.358 2519.969,2572.825 2538.258,2581.602 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2538.261,2581.601 2531.396,2569.006 2550.855,2574.736 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2550.858,2574.735 2542.062,2563.404 2562.188,2565.938 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2562.191,2565.936 2551.691,2556.164 2571.964,2555.437 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2571.966,2555.434 2560.034,2547.472 2579.927,2543.503 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2579.929,2543.5 2566.875,2537.555 2585.874,2530.446 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2585.875,2530.443 2572.036,2526.669 2589.649,2516.604 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2589.649,2516.601 2575.385,2515.096 2591.155,2502.336 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2591.155,2502.333 2576.833,2503.135 2590.352,2488.011 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2590.352,2488.008 2576.344,2491.098 2587.262,2474.001 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2587.261,2473.998 2573.931,2479.294 2581.965,2460.667 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2581.963,2460.665 2569.655,2468.031 2574.597,2448.356 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2574.595,2448.354 2563.628,2457.599 2565.35,2437.387 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2565.347,2437.385 2556.006,2448.269 2554.463,2428.043 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2554.46,2428.041 2546.985,2440.283 2542.218,2420.566 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2542.215,2420.564 2536.801,2433.847 2528.932,2415.15 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2528.929,2415.149 2525.716,2429.128 2514.95,2411.935 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.947,2411.935 2514.017,2426.249 2500.633,2411.005 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2500.629,2411.005 2502.008,2425.283 2486.352,2412.384 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2486.349,2412.385 2490,2426.256 2472.477,2416.036 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2472.474,2416.037 2478.303,2429.143 2459.368,2421.866 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2459.365,2421.867 2467.221,2433.869 2447.364,2429.723 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2447.361,2429.725 2457.04,2440.311 2436.775,2439.404 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2436.773,2439.407 2448.025,2448.303 2427.877,2450.659 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2427.875,2450.661 2440.408,2457.637 2420.899,2463.195 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2420.898,2463.198 2434.388,2468.073 2416.022,2476.688 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2416.022,2476.691 2430.119,2479.339 2413.374,2490.788 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2413.373,2490.792 2427.713,2491.144 2413.021,2505.131 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2413.021,2505.135 2427.231,2503.182 2414.973,2519.345 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2414.974,2519.348 2428.688,2515.141 2419.181,2533.062 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2419.182,2533.064 2432.043,2526.712 2425.534,2545.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2425.536,2545.928 2437.211,2537.595 2433.868,2557.604 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2433.87,2557.606 2444.058,2547.508 2443.968,2567.793 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2443.971,2567.796 2452.406,2556.195 2455.572,2576.231 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2455.574,2576.233 2462.04,2563.429 2468.379,2582.699 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2468.382,2582.7 2472.71,2569.025 2482.057,2587.028 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2482.06,2587.029 2484.138,2572.836 2496.253,2589.107 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2510.577,2606.085 2510.577,2588.989 2493.481,2606.085 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.477,2606.085 2496.219,2589.21 2476.602,2603.343 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2476.598,2603.342 2482.012,2587.125 2460.381,2597.928 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2460.378,2597.926 2468.323,2582.788 2445.24,2589.981 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2445.237,2589.979 2455.507,2576.311 2431.569,2579.708 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2431.566,2579.706 2443.896,2567.863 2419.723,2567.376 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2419.72,2567.373 2433.791,2557.661 2410.008,2553.302 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2410.006,2553.299 2425.452,2545.97 2402.677,2537.854 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2402.676,2537.85 2419.097,2533.093 2397.919,2521.428 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2397.918,2521.425 2414.89,2519.364 2395.858,2504.453 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2395.858,2504.449 2412.94,2505.137 2396.546,2487.366 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2396.547,2487.362 2413.298,2490.782 2399.966,2470.611 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2399.967,2470.608 2415.953,2476.67 2406.03,2454.622 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2406.031,2454.619 2420.838,2463.167 2414.58,2439.813 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2414.582,2439.81 2427.825,2450.622 2425.395,2426.566 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2425.397,2426.564 2436.734,2439.361 2438.194,2415.227 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2438.197,2415.224 2447.335,2429.674 2452.647,2406.087 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2452.65,2406.085 2459.352,2421.813 2468.379,2399.384 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2468.383,2399.383 2472.474,2415.982 2484.982,2395.291 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.986,2395.29 2486.362,2412.332 2502.027,2393.915 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.031,2393.915 2500.655,2410.956 2519.072,2395.29 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.076,2395.291 2514.984,2411.891 2535.676,2399.383 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2535.679,2399.384 2528.978,2415.112 2551.408,2406.085 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2551.411,2406.087 2542.274,2420.537 2565.861,2415.224 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2565.864,2415.227 2554.527,2428.023 2578.661,2426.564 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2578.664,2426.566 2565.421,2437.379 2589.476,2439.81 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2589.478,2439.813 2574.672,2448.361 2598.027,2454.619 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2598.028,2454.622 2582.043,2460.685 2604.091,2470.608 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2604.092,2470.611 2587.341,2474.031 2607.512,2487.362 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2607.512,2487.366 2590.429,2488.055 2608.2,2504.449 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2608.2,2504.453 2591.229,2502.392 2606.14,2521.425 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2606.139,2521.428 2589.717,2516.672 2601.382,2537.85 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2601.381,2537.854 2585.935,2530.524 2594.052,2553.299 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2594.05,2553.302 2579.98,2543.591 2584.338,2567.373 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2584.335,2567.376 2572.005,2555.532 2572.492,2579.706 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2572.49,2579.708 2562.219,2566.041 2558.822,2589.979 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2558.818,2589.981 2550.873,2574.843 2543.68,2597.926 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2543.677,2597.928 2538.263,2581.711 2527.46,2603.342 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2527.456,2603.343 2524.714,2586.467 2510.581,2606.085 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2493.828,2626.587 2493.508,2606.212 2514.203,2626.267 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2514.207,2626.267 2510.623,2606.207 2534.267,2622.682 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2534.271,2622.681 2527.515,2603.456 2553.496,2615.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2553.5,2615.924 2543.748,2598.031 2571.392,2606.172 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2571.396,2606.169 2558.9,2590.073 2587.492,2593.673 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2587.496,2593.67 2572.58,2579.787 2601.379,2578.754 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2601.382,2578.75 2584.432,2567.439 2612.693,2561.801 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2612.695,2561.797 2594.15,2553.351 2621.14,2543.252 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2621.142,2543.248 2601.482,2537.886 2626.503,2523.588 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2626.504,2523.583 2606.239,2521.446 2628.642,2503.319 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2628.642,2503.314 2608.297,2504.454 2627.502,2482.969 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2627.501,2482.964 2607.602,2487.354 2623.112,2463.065 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2623.111,2463.061 2604.174,2470.585 2615.586,2444.124 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2615.584,2444.12 2598.099,2454.584 2605.12,2426.635 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2605.117,2426.631 2589.537,2439.765 2591.983,2411.051 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2591.98,2411.048 2578.709,2426.511 2576.517,2397.776 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2576.513,2397.774 2565.894,2415.165 2559.122,2387.155 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2559.118,2387.152 2551.426,2406.022 2540.248,2379.461 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2540.244,2379.459 2535.678,2399.319 2520.384,2374.894 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2520.38,2374.894 2519.059,2395.228 2500.045,2373.573 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2500.041,2373.573 2501.999,2393.856 2479.758,2375.531 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2479.753,2375.532 2484.94,2395.239 2460.047,2380.719 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2460.043,2380.721 2468.323,2399.34 2441.424,2389.001 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2441.42,2389.004 2452.58,2406.053 2424.371,2400.164 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2424.367,2400.167 2438.117,2415.205 2409.328,2413.917 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2409.325,2413.92 2425.31,2426.558 2396.687,2429.905 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2396.685,2429.909 2414.49,2439.819 2386.775,2447.714 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2386.773,2447.718 2405.937,2454.644 2379.847,2466.883 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2379.846,2466.887 2399.873,2470.649 2376.084,2486.914 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2376.083,2486.918 2396.455,2487.419 2375.583,2507.29 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2375.583,2507.294 2395.771,2504.521 2378.357,2527.482 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2378.358,2527.486 2397.839,2521.51 2384.334,2546.968 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2384.335,2546.972 2402.606,2537.948 2393.359,2565.242 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2393.362,2565.246 2409.948,2553.408 2405.199,2581.832 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2405.202,2581.836 2419.675,2567.491 2419.547,2596.308 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2419.551,2596.312 2431.535,2579.831 2436.031,2608.295 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2436.035,2608.298 2445.22,2590.108 2454.225,2617.483 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2454.229,2617.485 2460.377,2598.057 2473.657,2623.633 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2473.661,2623.634 2476.614,2603.472 2493.823,2626.587 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2514.173,2650.706 2514.173,2626.419 2489.885,2650.706 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2489.88,2650.706 2493.776,2626.733 2465.907,2646.81 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2465.902,2646.808 2473.593,2623.771 2442.864,2639.117 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2442.859,2639.115 2454.146,2617.61 2421.353,2627.828 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2421.349,2627.825 2435.939,2608.409 2401.932,2613.235 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2401.928,2613.231 2419.444,2596.406 2385.104,2595.715 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2385.1,2595.711 2405.088,2581.914 2371.303,2575.722 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2371.3,2575.718 2393.243,2565.306 2360.888,2553.775 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2360.886,2553.77 2384.215,2547.013 2354.129,2530.441 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2354.128,2530.436 2378.239,2527.509 2351.201,2506.326 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2351.2,2506.32 2375.469,2507.298 2352.178,2482.052 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2352.179,2482.047 2375.976,2486.905 2357.037,2458.25 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2357.039,2458.245 2379.748,2466.857 2365.651,2435.536 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2365.654,2435.531 2386.687,2447.674 2377.797,2414.497 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2377.801,2414.492 2396.614,2429.853 2393.161,2395.679 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2393.165,2395.675 2409.271,2413.855 2411.344,2379.57 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2411.349,2379.566 2424.33,2400.094 2431.876,2366.585 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2431.881,2366.583 2441.401,2388.927 2454.225,2357.063 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2454.23,2357.061 2460.043,2380.643 2477.812,2351.249 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2477.818,2351.248 2479.772,2375.457 2502.026,2349.294 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.032,2349.294 2500.077,2373.502 2526.241,2351.248 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.246,2351.249 2520.434,2374.831 2549.828,2357.061 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2549.833,2357.063 2540.313,2379.407 2572.177,2366.583 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2572.182,2366.585 2559.201,2387.113 2592.709,2379.566 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2592.714,2379.57 2576.608,2397.749 2610.893,2395.675 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2610.897,2395.679 2592.084,2411.04 2626.258,2414.492 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2626.261,2414.497 2605.227,2426.641 2638.405,2435.531 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2638.407,2435.535 2615.698,2444.148 2647.02,2458.245 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2647.021,2458.25 2623.224,2463.108 2651.879,2482.047 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2651.88,2482.052 2627.612,2483.03 2652.858,2506.32 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2652.858,2506.325 2628.747,2503.398 2649.93,2530.436 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2649.929,2530.441 2626.6,2523.684 2643.172,2553.77 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2643.17,2553.775 2621.227,2543.363 2632.758,2575.718 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2632.755,2575.722 2612.767,2561.926 2618.958,2595.711 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2618.955,2595.715 2601.438,2578.89 2602.13,2613.231 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2602.126,2613.235 2587.535,2593.818 2582.709,2627.825 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2582.705,2627.828 2571.418,2606.323 2561.199,2639.115 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2561.194,2639.117 2553.503,2616.08 2538.156,2646.809 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2538.151,2646.81 2534.255,2622.837 2514.178,2650.706 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2490.378,2679.831 2489.924,2650.886 2519.323,2679.376 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2519.329,2679.376 2514.238,2650.879 2547.826,2674.284 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2547.833,2674.282 2538.235,2646.971 2575.144,2664.685 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2575.15,2664.683 2561.296,2639.265 2600.567,2650.829 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2600.573,2650.825 2582.821,2627.958 2623.439,2633.073 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2623.444,2633.069 2602.254,2613.346 2643.167,2611.879 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2643.171,2611.874 2619.092,2595.806 2659.239,2587.794 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2659.242,2587.789 2632.897,2575.792 2671.24,2561.444 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2671.242,2561.438 2643.314,2553.822 2678.858,2533.51 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2678.859,2533.503 2650.071,2530.466 2681.897,2504.715 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2681.897,2504.708 2652.994,2506.328 2680.277,2475.805 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2680.276,2475.799 2652.008,2482.034 2674.041,2447.53 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2674.039,2447.524 2647.137,2458.213 2663.35,2420.622 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2663.347,2420.615 2638.508,2435.482 2648.481,2395.776 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2648.478,2395.771 2626.344,2414.429 2629.819,2373.638 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2629.815,2373.633 2610.961,2395.6 2607.848,2354.78 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2607.843,2354.776 2592.757,2379.483 2583.136,2339.69 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2583.13,2339.687 2572.203,2366.494 2556.323,2328.76 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2556.317,2328.758 2549.832,2356.971 2528.105,2322.273 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2528.098,2322.272 2526.222,2351.159 2499.211,2320.396 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2499.204,2320.396 2501.987,2349.21 2470.39,2323.178 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2470.384,2323.179 2477.752,2351.174 2442.389,2330.548 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2442.383,2330.55 2454.146,2357 2415.933,2342.314 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2415.927,2342.317 2431.781,2366.538 2391.706,2358.171 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2391.701,2358.175 2411.235,2379.539 2370.337,2377.709 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2370.333,2377.714 2393.041,2395.668 2352.379,2400.422 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2352.375,2400.428 2377.67,2414.506 2338.297,2425.722 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2338.294,2425.728 2365.519,2435.567 2328.455,2452.953 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2328.454,2452.959 2356.905,2458.303 2323.11,2481.41 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2323.109,2481.416 2352.048,2482.127 2322.398,2510.356 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2322.398,2510.362 2351.077,2506.422 2326.338,2539.041 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2326.34,2539.047 2354.015,2530.558 2334.83,2566.723 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2334.832,2566.729 2360.787,2553.91 2347.651,2592.684 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2347.655,2592.689 2371.217,2575.873 2364.471,2616.252 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2364.475,2616.257 2385.035,2595.878 2384.854,2636.817 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2384.859,2636.821 2401.884,2613.408 2408.271,2653.846 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2408.277,2653.849 2421.326,2628.009 2434.118,2666.898 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2434.124,2666.901 2442.858,2639.302 2461.723,2675.635 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2461.729,2675.637 2465.923,2646.994 2490.372,2679.831 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2519.281,2714.095 2519.281,2679.592 2484.777,2714.095 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2484.77,2714.095 2490.304,2680.038 2450.713,2708.56 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2450.706,2708.558 2461.632,2675.831 2417.978,2697.632 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2417.971,2697.629 2434.006,2667.078 2387.42,2681.595 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2387.413,2681.59 2408.141,2654.007 2359.83,2660.863 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2359.824,2660.858 2384.708,2636.957 2335.923,2635.974 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2335.918,2635.968 2364.314,2616.368 2316.318,2607.572 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2316.314,2607.566 2347.486,2592.774 2301.523,2576.394 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2301.52,2576.386 2334.661,2566.787 2291.921,2543.245 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2291.919,2543.238 2326.171,2539.079 2287.76,2508.986 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2287.76,2508.979 2322.235,2510.368 2289.149,2474.503 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2289.15,2474.496 2322.956,2481.397 2296.052,2440.689 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2296.054,2440.682 2328.315,2452.917 2308.289,2408.421 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2308.292,2408.414 2338.173,2425.666 2325.544,2378.533 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2325.548,2378.527 2352.275,2400.348 2347.37,2351.801 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2347.375,2351.795 2370.255,2377.621 2373.201,2328.915 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2373.207,2328.91 2391.648,2358.072 2402.369,2310.469 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2402.376,2310.466 2415.9,2342.208 2434.118,2296.942 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2434.125,2296.939 2442.383,2330.44 2467.626,2288.682 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2467.634,2288.681 2470.41,2323.072 2502.025,2285.905 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.033,2285.905 2499.257,2320.296 2536.424,2288.681 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.432,2288.682 2528.175,2322.183 2569.933,2296.939 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2569.94,2296.942 2556.416,2328.684 2601.682,2310.466 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2601.689,2310.469 2583.248,2339.631 2630.851,2328.91 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2630.857,2328.915 2607.977,2354.741 2656.683,2351.795 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2656.689,2351.801 2629.962,2373.622 2678.51,2378.527 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2678.514,2378.533 2648.634,2395.785 2695.766,2408.414 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2695.77,2408.421 2663.508,2420.656 2708.004,2440.682 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2708.007,2440.689 2674.2,2447.591 2714.908,2474.495 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2714.909,2474.503 2680.434,2475.892 2716.298,2508.979 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2716.298,2508.986 2682.046,2504.827 2712.139,2543.238 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2712.137,2543.245 2678.996,2533.646 2702.538,2576.386 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2702.535,2576.394 2671.364,2561.602 2687.744,2607.566 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2687.74,2607.572 2659.344,2587.972 2668.14,2635.968 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2668.135,2635.974 2643.251,2612.073 2644.234,2660.858 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2644.228,2660.863 2623.5,2633.28 2616.645,2681.591 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2616.638,2681.594 2600.604,2651.043 2586.087,2697.629 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2586.08,2697.632 2575.154,2664.905 2553.352,2708.558 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2553.345,2708.56 2547.81,2674.504 2519.288,2714.095 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2485.478,2755.471 2484.832,2714.352 2526.597,2754.825 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2526.606,2754.824 2519.373,2714.341 2567.089,2747.59 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2567.098,2747.588 2553.464,2708.789 2605.897,2733.954 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2605.905,2733.95 2586.224,2697.841 2642.014,2714.27 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2642.022,2714.264 2616.803,2681.78 2674.506,2689.046 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2674.513,2689.04 2644.41,2661.021 2702.531,2658.937 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2702.537,2658.929 2668.33,2636.103 2725.364,2624.722 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2725.368,2624.714 2687.942,2607.671 2742.412,2587.288 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2742.416,2587.28 2702.74,2576.46 2753.235,2547.604 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2753.237,2547.595 2712.34,2543.28 2757.552,2506.698 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2757.552,2506.688 2716.492,2508.99 2755.251,2465.629 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2755.25,2465.62 2715.091,2474.478 2746.392,2425.461 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2746.389,2425.452 2708.171,2440.637 2731.204,2387.234 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2731.2,2387.225 2695.913,2408.344 2710.081,2351.938 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2710.076,2351.931 2678.633,2378.437 2683.57,2320.488 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2683.563,2320.481 2656.78,2351.688 2652.357,2293.698 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2652.349,2293.692 2630.918,2328.791 2617.25,2272.261 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2617.242,2272.257 2601.719,2310.339 2579.16,2256.734 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2579.151,2256.732 2569.938,2296.811 2539.073,2247.518 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2539.063,2247.517 2536.398,2288.555 2498.026,2244.852 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2498.016,2244.852 2501.969,2285.786 2457.083,2248.805 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2457.073,2248.806 2467.541,2288.576 2417.303,2259.274 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2417.295,2259.277 2434.007,2296.853 2379.719,2275.989 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2379.711,2275.993 2402.234,2310.402 2345.303,2298.516 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2345.295,2298.521 2373.046,2328.871 2314.946,2326.272 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2314.939,2326.279 2347.199,2351.784 2289.434,2358.539 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2289.429,2358.546 2325.362,2378.546 2269.429,2394.479 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2269.425,2394.488 2308.101,2408.465 2255.448,2433.164 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2255.446,2433.173 2295.863,2440.765 2247.854,2473.59 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2247.853,2473.6 2288.964,2474.61 2246.842,2514.711 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2246.843,2514.72 2287.584,2509.123 2252.44,2555.462 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2252.442,2555.471 2291.759,2543.411 2264.503,2594.787 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2264.506,2594.796 2301.378,2576.585 2282.717,2631.668 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2282.722,2631.676 2316.196,2607.786 2306.612,2665.149 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2306.618,2665.156 2335.826,2636.206 2335.568,2694.364 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2335.575,2694.37 2359.761,2661.11 2368.836,2718.556 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2368.844,2718.561 2387.381,2681.851 2405.553,2737.098 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2405.562,2737.101 2417.97,2697.894 2444.77,2749.51 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2444.778,2749.512 2450.737,2708.822 2485.469,2755.47 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2526.537,2804.147 2526.537,2755.131 2477.521,2804.147 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2477.51,2804.146 2485.373,2755.765 2429.129,2796.283 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2429.118,2796.281 2444.64,2749.787 2382.625,2780.759 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2382.615,2780.755 2405.394,2737.353 2339.214,2757.976 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2339.204,2757.97 2368.65,2718.784 2300.019,2728.524 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2300.011,2728.517 2335.361,2694.562 2266.056,2693.166 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2266.049,2693.158 2306.389,2665.314 2238.205,2652.819 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2238.2,2652.81 2282.483,2631.797 2217.187,2608.526 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2217.183,2608.516 2264.264,2594.879 2203.546,2561.435 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2203.544,2561.424 2252.202,2555.516 2197.636,2512.766 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2197.635,2512.755 2246.611,2514.729 2199.609,2463.779 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2199.61,2463.768 2247.635,2473.573 2209.415,2415.743 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2209.418,2415.732 2255.248,2433.114 2226.799,2369.902 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2226.804,2369.891 2269.253,2394.399 2251.312,2327.442 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2251.318,2327.434 2289.286,2358.433 2282.318,2289.466 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2282.325,2289.458 2314.829,2326.147 2319.014,2256.954 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2319.023,2256.948 2345.22,2298.375 2360.451,2230.75 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2360.46,2230.745 2379.673,2275.839 2405.554,2211.532 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2405.564,2211.529 2417.295,2259.121 2453.156,2199.799 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2453.167,2199.797 2457.111,2248.654 2502.024,2195.853 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.035,2195.853 2498.09,2244.71 2550.892,2199.797 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2550.902,2199.799 2539.172,2247.39 2598.494,2211.529 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2598.504,2211.532 2579.292,2256.626 2643.598,2230.745 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2643.607,2230.75 2617.41,2272.178 2685.035,2256.948 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2685.044,2256.954 2652.541,2293.643 2721.733,2289.458 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2721.74,2289.466 2683.773,2320.466 2752.74,2327.434 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2752.747,2327.443 2710.298,2351.95 2777.254,2369.892 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2777.259,2369.901 2731.429,2387.283 2794.641,2415.732 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2794.644,2415.742 2746.618,2425.547 2804.448,2463.768 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2804.449,2463.779 2755.473,2465.752 2806.423,2512.755 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2806.423,2512.766 2757.764,2506.857 2800.514,2561.424 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2800.512,2561.435 2753.431,2547.798 2786.875,2608.516 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2786.871,2608.526 2742.588,2587.513 2765.858,2652.809 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2765.853,2652.819 2725.513,2624.975 2738.009,2693.158 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2738.002,2693.167 2702.652,2659.212 2704.048,2728.517 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2704.039,2728.524 2674.593,2689.339 2664.854,2757.97 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2664.844,2757.976 2642.066,2714.574 2621.443,2780.755 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2621.433,2780.759 2605.911,2734.266 2574.939,2796.281 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2574.929,2796.283 2567.066,2747.902 2526.548,2804.146 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2478.516,2862.926 2477.598,2804.511 2536.931,2862.008 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2536.944,2862.007 2526.667,2804.496 2594.454,2851.73 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2594.467,2851.727 2575.099,2796.609 2649.585,2832.358 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2649.597,2832.353 2621.637,2781.056 2700.894,2804.394 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2700.905,2804.387 2665.079,2758.239 2747.052,2768.561 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2747.062,2768.552 2704.298,2728.749 2786.866,2725.788 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2786.874,2725.778 2738.278,2693.35 2819.302,2677.182 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2819.308,2677.171 2766.14,2652.958 2843.521,2624.003 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2843.525,2623.99 2787.162,2608.62 2858.896,2567.627 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2858.898,2567.614 2800.799,2561.484 2865.028,2509.515 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2865.029,2509.502 2806.698,2512.771 2861.76,2451.172 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2861.758,2451.159 2804.708,2463.742 2849.175,2394.108 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2849.171,2394.096 2794.877,2415.668 2827.598,2339.803 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2827.593,2339.791 2777.463,2369.793 2797.591,2289.661 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2797.583,2289.651 2752.915,2327.305 2759.928,2244.983 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2759.919,2244.974 2721.87,2289.306 2715.587,2206.924 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2715.576,2206.917 2685.131,2256.779 2665.714,2176.472 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2665.703,2176.466 2643.65,2230.566 2611.603,2154.413 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2611.59,2154.409 2598.502,2211.346 2554.654,2141.321 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2554.641,2141.319 2550.855,2199.618 2496.342,2137.533 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2496.329,2137.533 2501.944,2195.685 2438.177,2143.149 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2438.164,2143.151 2453.035,2199.648 2381.667,2158.021 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2381.655,2158.026 2405.395,2211.406 2328.274,2181.766 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2328.263,2181.773 2360.259,2230.654 2279.382,2213.769 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2279.371,2213.777 2318.794,2256.892 2236.256,2253.2 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2236.247,2253.209 2282.075,2289.443 2200.014,2299.038 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2200.007,2299.049 2251.054,2327.461 2171.594,2350.096 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2171.588,2350.108 2226.532,2369.964 2151.732,2405.052 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2151.729,2405.064 2209.147,2415.85 2140.944,2462.482 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2140.942,2462.495 2199.346,2463.93 2139.507,2520.899 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2139.508,2520.912 2197.386,2512.96 2147.46,2578.79 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2147.463,2578.803 2203.316,2561.67 2164.596,2634.656 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2164.601,2634.668 2216.982,2608.797 2190.472,2687.05 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2190.479,2687.061 2238.032,2653.122 2224.417,2734.614 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2224.425,2734.624 2265.918,2693.497 2265.552,2776.117 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2265.563,2776.125 2299.921,2728.875 2312.813,2810.484 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2312.824,2810.49 2339.158,2758.34 2364.974,2836.824 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2364.986,2836.83 2382.614,2781.131 2420.685,2854.457 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2420.698,2854.46 2429.162,2796.655 2478.503,2862.925 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2536.845,2932.075 2536.845,2862.443 2467.213,2932.075 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2467.197,2932.074 2478.367,2863.343 2398.466,2920.904 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2398.451,2920.9 2420.501,2854.851 2332.402,2898.85 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2332.388,2898.844 2364.748,2837.188 2270.731,2866.484 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2270.718,2866.476 2312.549,2810.808 2215.051,2824.644 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2215.039,2824.634 2265.258,2776.398 2166.803,2774.415 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2166.793,2774.403 2224.1,2734.847 2127.237,2717.096 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2127.229,2717.083 2190.139,2687.232 2097.378,2654.174 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2097.373,2654.159 2164.256,2634.786 2078,2587.275 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2077.997,2587.26 2147.122,2578.867 2069.604,2518.135 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2069.603,2518.12 2139.179,2520.924 2072.407,2448.544 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2072.408,2448.528 2140.634,2462.457 2086.337,2380.303 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2086.341,2380.288 2151.449,2404.98 2111.033,2315.18 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2111.04,2315.166 2171.344,2349.982 2145.856,2254.862 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2145.865,2254.85 2199.803,2298.888 2189.904,2200.912 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2189.915,2200.901 2236.09,2253.021 2242.036,2154.726 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2242.048,2154.716 2279.264,2213.569 2300.901,2117.5 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2300.915,2117.493 2328.208,2181.553 2364.975,2090.199 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2364.99,2090.194 2381.654,2157.803 2432.599,2073.53 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2432.615,2073.528 2438.218,2142.934 2502.021,2067.925 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.037,2067.925 2496.434,2137.331 2571.444,2073.528 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2571.459,2073.53 2554.795,2141.139 2639.068,2090.194 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2639.083,2090.199 2611.789,2154.26 2703.144,2117.493 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2703.157,2117.5 2665.941,2176.353 2762.01,2154.717 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2762.023,2154.726 2715.848,2206.846 2814.144,2200.901 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2814.154,2200.912 2760.217,2244.951 2858.193,2254.85 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2858.202,2254.863 2797.898,2289.679 2893.018,2315.166 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2893.025,2315.18 2827.917,2339.872 2917.717,2380.288 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2917.721,2380.303 2849.496,2394.231 2931.649,2448.528 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2931.651,2448.544 2862.075,2451.347 2934.455,2518.12 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2934.455,2518.135 2865.33,2509.742 2926.062,2587.26 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2926.058,2587.275 2859.175,2567.902 2906.685,2654.159 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2906.68,2654.173 2843.77,2624.323 2876.829,2717.083 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2876.821,2717.096 2819.514,2677.541 2837.265,2774.403 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2837.255,2774.415 2787.036,2726.179 2789.019,2824.634 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2789.007,2824.645 2747.176,2768.977 2733.34,2866.476 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2733.327,2866.484 2700.967,2804.827 2671.67,2898.844 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2671.656,2898.85 2649.605,2832.801 2605.606,2920.901 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2605.592,2920.904 2594.422,2852.173 2536.861,2932.074 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2468.626,3015.577 2467.323,2932.592 2551.611,3014.273 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2551.629,3014.271 2537.031,2932.571 2633.33,2999.673 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2633.348,2999.668 2605.833,2921.367 2711.649,2972.153 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2711.666,2972.146 2671.947,2899.272 2784.539,2932.427 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2784.554,2932.416 2733.66,2866.858 2850.113,2881.521 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2850.127,2881.509 2789.375,2824.964 2906.672,2820.757 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2906.683,2820.743 2837.648,2774.675 2952.751,2751.707 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2952.76,2751.692 2877.229,2717.295 2987.157,2676.16 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2987.163,2676.143 2907.093,2654.307 3008.999,2596.072 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3009.002,2596.054 2926.466,2587.345 3017.711,2513.517 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3017.711,2513.499 2934.847,2518.143 3013.067,2430.634 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3013.065,2430.615 2932.018,2448.492 2995.189,2349.569 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2995.183,2349.551 2918.053,2380.197 2964.538,2272.421 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2964.529,2272.405 2893.314,2315.026 2921.908,2201.189 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2921.897,2201.175 2858.441,2254.668 2868.404,2137.719 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2868.391,2137.706 2814.338,2200.685 2805.412,2083.653 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2805.397,2083.642 2762.146,2154.476 2734.562,2040.391 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2734.546,2040.383 2703.218,2117.238 2657.691,2009.055 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2657.673,2009.049 2639.079,2089.934 2576.788,1990.456 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2576.77,1990.453 2571.392,2073.273 2493.949,1985.075 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2493.931,1985.075 2501.908,2067.686 2411.32,1993.052 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2411.302,1993.055 2432.427,2073.316 2331.041,2014.18 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2331.023,2014.187 2364.75,2090.02 2255.19,2047.913 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2255.174,2047.922 2300.628,2117.363 2185.733,2093.376 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2185.718,2093.387 2241.723,2154.638 2124.468,2149.392 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2124.455,2149.406 2189.56,2200.879 2072.982,2214.51 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2072.972,2214.526 2145.49,2254.889 2032.608,2287.044 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2032.6,2287.061 2110.655,2315.269 2004.393,2365.115 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2004.388,2365.133 2085.956,2380.455 1989.066,2446.701 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1989.064,2446.72 2072.034,2448.759 1987.025,2529.69 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1987.026,2529.708 2069.249,2518.411 1998.323,2611.931 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1998.327,2611.949 2077.673,2587.609 2022.667,2691.294 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2022.674,2691.312 2097.087,2654.559 2059.426,2765.725 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2059.436,2765.741 2126.991,2717.527 2107.65,2833.295 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2107.661,2833.31 2166.607,2774.884 2166.087,2892.255 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2166.101,2892.267 2214.911,2825.143 2233.226,2941.077 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2233.242,2941.087 2270.652,2867.002 2307.327,2978.497 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2307.344,2978.505 2332.386,2899.378 2386.471,3003.547 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2386.489,3003.551 2398.514,2921.432 2468.608,3015.576 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2551.49,3113.813 2551.49,3014.891 2452.568,3113.813 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2452.546,3113.811 2468.415,3016.171 2354.906,3097.943 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2354.885,3097.937 2386.21,3004.107 2261.055,3066.612 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2261.034,3066.604 2307.005,2979.013 2173.444,3020.633 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2173.426,3020.62 2232.852,2941.539 2094.344,2961.194 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2094.327,2961.18 2165.669,2892.655 2025.802,2889.838 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2025.788,2889.82 2107.198,2833.627 1969.595,2808.41 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1969.583,2808.392 2058.954,2765.985 1927.177,2719.021 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1927.169,2719 2022.184,2691.479 1899.647,2623.985 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1899.643,2623.963 1997.843,2612.04 1887.719,2525.763 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1887.719,2525.741 1986.559,2529.724 1891.702,2426.9 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1891.704,2426.879 1988.626,2446.665 1911.491,2329.957 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1911.497,2329.935 2003.99,2365.013 1946.575,2237.443 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1946.584,2237.422 2032.253,2286.883 1996.045,2151.754 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1996.058,2151.736 2072.683,2214.298 2058.62,2075.111 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2058.635,2075.095 2124.232,2149.139 2132.679,2009.499 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2132.697,2009.485 2185.566,2093.092 2216.303,1956.615 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2216.323,1956.605 2255.097,2047.61 2307.328,1917.831 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2307.349,1917.824 2331.023,2013.871 2403.396,1894.151 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2403.418,1894.147 2411.378,1992.748 2502.018,1886.188 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.04,1886.188 2494.08,1984.788 2600.64,1894.147 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2600.662,1894.151 2576.989,1990.198 2696.709,1917.824 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2696.73,1917.831 2657.956,2008.837 2787.735,1956.605 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2787.755,1956.615 2734.885,2040.223 2871.362,2009.485 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2871.38,2009.499 2805.783,2083.542 2945.423,2075.095 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2945.438,2075.111 2868.814,2137.674 3008.001,2151.736 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3008.013,2151.754 2922.345,2201.215 3057.473,2237.422 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3057.483,2237.442 2964.99,2272.52 3092.561,2329.935 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3092.567,2329.956 2995.645,2349.743 3112.354,2426.878 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3112.357,2426.9 3013.516,2430.884 3116.34,2525.741 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3116.339,2525.763 3018.139,2513.839 3104.416,2623.963 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3104.41,2623.985 3009.395,2596.463 3076.889,2719 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3076.882,2719.021 2987.511,2676.614 3034.475,2808.391 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3034.463,2808.41 2953.053,2752.217 2978.27,2889.821 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2978.256,2889.838 2906.914,2821.313 2909.731,2961.18 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2909.714,2961.195 2850.288,2882.113 2830.633,3020.621 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2830.614,3020.632 2784.643,2933.042 2743.023,3066.603 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2743.003,3066.612 2711.678,2972.782 2649.173,3097.937 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2649.151,3097.943 2633.283,3000.302 2551.511,3113.811 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2454.576,3232.436 2452.724,3114.547 2572.465,3230.584 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2572.491,3230.581 2551.753,3114.516 2688.557,3209.843 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2688.582,3209.835 2649.494,3098.6 2799.818,3170.747 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2799.842,3170.737 2743.416,3067.212 2903.366,3114.311 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2903.389,3114.296 2831.087,3021.163 2996.521,3041.995 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2996.541,3041.977 2910.237,2961.648 3076.87,2955.672 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3076.886,2955.652 2978.814,2890.207 3142.331,2857.579 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3142.344,2857.557 3035.043,2808.692 3191.209,2750.256 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3191.218,2750.231 3077.468,2719.211 3222.238,2636.481 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3222.242,2636.455 3104.99,2624.084 3234.614,2519.203 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3234.615,2519.176 3116.896,2525.773 3228.017,2401.457 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3228.014,2401.431 3112.878,2426.827 3202.618,2286.295 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3202.611,2286.27 3093.039,2329.806 3159.075,2176.699 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3159.063,2176.675 3057.895,2237.223 3098.515,2075.506 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3098.5,2075.485 3008.353,2151.478 3022.507,1985.339 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3022.488,1985.32 2945.699,2074.789 2933.019,1908.531 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2932.997,1908.516 2871.555,2009.144 2832.369,1847.073 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2832.346,1847.061 2787.841,1956.243 2723.164,1802.556 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2723.139,1802.549 2696.725,1917.455 2608.233,1776.134 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2608.207,1776.13 2600.566,1893.786 2490.551,1768.49 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2490.524,1768.49 2501.856,1885.848 2373.167,1779.823 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2373.141,1779.827 2403.152,1893.847 2259.121,1809.838 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2259.096,1809.847 2307.008,1917.576 2151.366,1857.759 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2151.343,1857.772 2215.916,1956.421 2052.694,1922.345 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2052.673,1922.361 2132.234,2009.374 1965.66,2001.922 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1965.642,2001.941 2058.131,2075.065 1892.518,2094.43 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1892.504,2094.451 1995.525,2151.792 1835.164,2197.472 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1835.152,2197.496 1946.037,2237.569 1795.08,2308.381 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1795.073,2308.406 1910.95,2330.173 1773.307,2424.283 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1773.304,2424.31 1891.172,2427.206 1770.407,2542.178 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1770.409,2542.204 1887.215,2526.156 1786.457,2659.01 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1786.463,2659.036 1899.183,2624.458 1821.041,2771.755 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1821.05,2771.78 1926.763,2719.569 1873.261,2877.493 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1873.276,2877.515 1969.244,2809.022 1941.768,2973.484 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1941.785,2973.504 2025.523,2890.504 2024.785,3057.243 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2024.805,3057.26 2094.146,2961.902 2120.163,3126.601 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2120.186,3126.614 2173.332,3021.368 2225.432,3179.76 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2225.457,3179.771 2261.032,3067.362 2337.865,3215.345 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2337.891,3215.351 2354.974,3098.692 2454.55,3232.434 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2572.293,3371.99 2572.293,3231.462 2431.765,3371.99 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2431.733,3371.988 2454.276,3233.279 2293.024,3349.446 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2292.994,3349.438 2337.495,3216.141 2159.697,3304.937 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2159.669,3304.925 2224.976,3180.493 2035.237,3239.618 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2035.21,3239.6 2119.632,3127.256 1922.866,3155.179 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1922.842,3155.158 2024.191,3057.811 1825.494,3053.809 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1825.474,3053.784 1941.127,2973.955 1745.645,2938.131 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1745.629,2938.105 1872.59,2877.862 1685.386,2811.145 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1685.375,2811.115 1820.355,2772.017 1646.277,2676.135 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1646.271,2676.104 1785.775,2659.165 1629.332,2536.6 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1629.331,2536.568 1769.745,2542.227 1634.989,2396.154 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1634.993,2396.123 1772.682,2424.232 1663.102,2258.434 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1663.111,2258.404 1794.508,2308.236 1712.943,2127.007 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1712.957,2126.978 1834.658,2197.243 1783.221,2005.277 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1783.239,2005.251 1892.093,2094.128 1872.116,1896.397 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1872.138,1896.375 1965.325,2001.562 1977.325,1803.187 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1977.35,1803.168 2052.458,1921.941 2096.123,1728.06 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2096.151,1728.045 2151.233,1857.329 2225.434,1672.963 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2225.464,1672.953 2259.095,1809.398 2361.909,1639.322 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2361.94,1639.317 2373.248,1779.39 2502.013,1628.009 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.045,1628.01 2490.737,1768.082 2642.118,1639.317 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2642.149,1639.322 2608.518,1775.767 2778.594,1672.953 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2778.624,1672.963 2723.541,1802.246 2907.907,1728.045 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2907.935,1728.06 2832.827,1846.834 3026.708,1803.168 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3026.734,1803.187 2933.546,1908.374 3131.921,1896.375 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3131.943,1896.397 3023.088,1985.274 3220.819,2005.251 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3220.837,2005.277 3099.135,2075.542 3291.101,2126.979 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3291.115,2127.007 3159.719,2176.839 3340.947,2258.403 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3340.955,2258.433 3203.267,2286.543 3369.065,2396.122 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3369.069,2396.154 3228.654,2401.812 3374.727,2536.568 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3374.727,2536.599 3235.222,2519.661 3357.788,2676.104 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3357.781,2676.134 3222.8,2637.037 3318.683,2811.115 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3318.673,2811.144 3191.712,2750.901 3258.429,2938.105 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3258.412,2938.132 3142.76,2858.302 3178.583,3053.785 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3178.564,3053.809 3077.215,2956.461 3081.217,3155.159 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3081.192,3155.18 2996.771,3042.835 2968.848,3239.601 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2968.821,3239.617 2903.514,3115.186 2844.389,3304.924 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2844.36,3304.937 2799.859,3171.641 2711.063,3349.438 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2711.033,3349.446 2688.491,3210.737 2572.324,3371.988 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2434.617,3540.509 2431.986,3373.034 2602.092,3537.878 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2602.129,3537.874 2572.667,3372.99 2767.013,3508.412 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2767.049,3508.402 2711.52,3350.379 2925.072,3452.873 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2925.106,3452.858 2844.947,3305.789 3072.175,3372.699 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3072.206,3372.678 2969.494,3240.372 3204.512,3269.965 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3204.54,3269.94 3081.934,3155.824 3318.656,3147.335 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3318.679,3147.305 3179.356,3054.334 3411.651,3007.981 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3411.669,3007.95 3259.236,2938.532 3481.087,2855.517 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3481.1,2855.481 3319.505,2811.414 3525.167,2693.887 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3525.174,2693.851 3358.604,2676.275 3542.75,2527.28 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3542.75,2527.242 3375.517,2536.614 3533.378,2360.009 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3533.373,2359.972 3369.809,2396.049 3497.296,2196.408 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3497.285,2196.373 3341.626,2258.22 3435.438,2040.714 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3435.421,2040.679 3291.699,2126.695 3349.405,1896.958 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3349.383,1896.927 3221.32,2004.884 3241.427,1768.865 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3241.4,1768.838 3132.313,1895.939 3114.299,1659.751 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3114.269,1659.729 3026.983,1802.683 2971.315,1572.443 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2971.281,1572.426 2908.057,1727.531 2816.177,1509.202 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2816.141,1509.191 2778.616,1672.429 2652.903,1471.666 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2652.866,1471.66 2642.012,1638.804 2485.723,1460.807 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2485.686,1460.807 2501.784,1627.527 2318.966,1476.906 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2318.928,1476.912 2361.562,1638.891 2156.95,1519.546 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2156.915,1519.558 2224.98,1672.6 2003.873,1587.623 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2003.84,1587.641 2095.573,1727.783 1863.698,1679.375 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1863.668,1679.397 1976.694,1803.009 1740.056,1792.423 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1740.031,1792.45 1871.421,1896.331 1636.15,1923.841 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1636.13,1923.872 1782.482,2005.331 1554.671,2070.225 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1554.655,2070.259 1712.179,2127.186 1497.727,2227.783 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1497.718,2227.82 1662.334,2258.741 1466.796,2392.436 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1466.792,2392.473 1634.237,2396.588 1462.677,2559.918 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1462.679,2559.956 1628.615,2537.157 1485.477,2725.892 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1485.486,2725.929 1645.617,2676.807 1534.608,2886.059 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1534.621,2886.094 1684.798,2811.922 1608.793,3036.271 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1608.813,3036.303 1745.148,2939.002 1706.115,3172.638 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1706.138,3172.667 1825.099,3054.755 1824.049,3291.627 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1824.078,3291.652 1922.584,3156.185 1959.545,3390.158 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1959.577,3390.177 2035.077,3240.663 2109.092,3465.677 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2109.127,3465.691 2159.665,3306.002 2268.816,3516.23 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2268.852,3516.238 2293.12,3350.51 2434.58,3540.506 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2601.847,3738.762 2601.847,3539.125 2402.21,3738.762 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2402.166,3738.758 2434.19,3541.707 2205.114,3706.734 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2205.071,3706.723 2268.289,3517.36 2015.708,3643.504 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2015.667,3643.488 2108.443,3466.718 1838.897,3550.712 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1838.86,3550.687 1958.79,3391.088 1679.261,3430.756 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1679.227,3430.727 1823.206,3292.434 1540.934,3286.748 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1540.906,3286.713 1705.204,3173.307 1427.5,3122.416 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1427.477,3122.378 1607.839,3036.796 1341.894,2942.016 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1341.879,2941.974 1533.633,2886.431 1286.336,2750.219 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1286.327,2750.176 1484.508,2726.112 1262.263,2551.994 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1262.262,2551.949 1461.737,2559.988 1270.3,2352.474 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1270.305,2352.43 1465.908,2392.363 1310.238,2156.828 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1310.25,2156.785 1496.914,2227.577 1381.043,1970.121 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1381.062,1970.08 1553.952,2069.899 1480.88,1797.189 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1480.906,1797.153 1635.546,1923.413 1607.166,1642.513 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1607.196,1642.481 1739.58,1791.912 1756.627,1510.098 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1756.663,1510.07 1863.362,1678.802 1925.394,1403.371 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1925.433,1403.351 2003.684,1587.012 2109.095,1325.099 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2109.137,1325.086 2156.913,1518.922 2302.973,1277.309 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2303.017,1277.302 2319.081,1476.292 2502.007,1261.238 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.051,1261.238 2485.987,1460.228 2701.041,1277.302 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2701.085,1277.309 2653.309,1471.145 2894.921,1325.086 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2894.963,1325.099 2816.712,1508.761 3078.625,1403.351 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3078.665,1403.372 2971.965,1572.103 3247.396,1510.071 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3247.432,1510.097 3115.048,1659.528 3396.862,1642.481 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3396.893,1642.513 3242.253,1768.773 3523.153,1797.153 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3523.177,1797.19 3350.287,1897.008 3622.996,1970.08 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3623.016,1970.121 3436.352,2040.913 3693.808,2156.784 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3693.82,2156.827 3498.217,2196.76 3733.752,2352.43 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3733.758,2352.474 3534.283,2360.513 3741.796,2551.949 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3741.795,2551.994 3543.614,2527.93 3717.732,2750.175 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3717.721,2750.219 3525.967,2694.676 3662.179,2941.974 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3662.164,2942.016 3481.802,2856.433 3576.581,3122.378 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3576.558,3122.416 3412.26,3009.01 3463.151,3286.714 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3463.124,3286.749 3319.146,3148.455 3324.831,3430.728 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3324.796,3430.757 3204.866,3271.159 3165.198,3550.688 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3165.16,3550.711 3072.384,3373.941 2988.391,3643.487 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2988.349,3643.505 2925.13,3454.142 2798.986,3706.724 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2798.943,3706.734 2766.919,3509.683 2601.891,3738.758 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2406.262,3978.161 2402.525,3740.244 2644.179,3974.423 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2644.232,3974.418 2602.379,3740.182 2878.469,3932.565 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2878.52,3932.55 2799.635,3708.061 3103.01,3853.664 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3103.058,3853.644 2989.183,3644.716 3311.986,3739.769 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3312.03,3739.738 3166.116,3551.782 3499.986,3593.824 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3500.026,3593.788 3325.851,3431.673 3662.141,3419.613 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3662.174,3419.571 3464.249,3287.494 3794.25,3221.646 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3794.276,3221.601 3577.728,3122.985 3892.893,3005.053 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3892.911,3005.002 3663.347,2942.399 3955.513,2775.438 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3955.523,2775.387 3718.891,2750.419 3980.491,2538.754 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3980.492,2538.7 3742.918,2552.015 3967.178,2301.127 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3967.171,2301.074 3734.81,2352.326 3915.919,2068.713 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3915.904,2068.663 3694.773,2156.524 3828.042,1847.532 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3828.019,1847.483 3623.845,1969.678 3705.824,1643.309 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3705.792,1643.267 3523.864,1796.631 3552.428,1461.339 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3552.39,1461.301 3397.42,1641.863 3371.829,1306.331 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3371.785,1306.299 3247.786,1509.382 3168.703,1182.299 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3168.656,1182.275 3078.839,1402.619 2948.312,1092.458 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2948.261,1092.443 2894.953,1324.341 2716.363,1039.134 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2716.311,1039.126 2700.892,1276.573 2478.864,1023.707 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2478.811,1023.708 2501.681,1260.553 2241.966,1046.578 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2241.914,1046.587 2302.48,1276.696 2011.805,1107.153 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2011.755,1107.171 2108.449,1324.585 1794.341,1203.865 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1794.294,1203.891 1924.612,1402.978 1595.207,1334.208 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1595.165,1334.24 1755.73,1509.845 1419.56,1494.806 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1419.523,1494.845 1606.179,1642.42 1271.949,1681.501 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1271.92,1681.545 1479.831,1797.266 1156.198,1889.455 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1156.176,1889.504 1379.957,1970.376 1075.304,2113.285 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1075.29,2113.336 1309.147,2157.264 1031.363,2347.193 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1031.357,2347.246 1269.231,2353.092 1025.511,2585.121 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1025.514,2585.174 1261.245,2552.786 1057.902,2820.905 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1057.914,2820.957 1285.398,2751.175 1127.697,3048.441 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1127.716,3048.491 1341.06,2943.121 1233.085,3261.835 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1233.114,3261.88 1426.793,3123.652 1371.342,3455.559 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1371.375,3455.599 1540.372,3288.093 1538.881,3624.597 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1538.923,3624.632 1678.862,3432.185 1731.369,3764.571 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1731.415,3764.597 1838.671,3552.196 1943.817,3871.854 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1943.867,3871.875 2015.662,3645.018 2170.723,3943.67 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2170.774,3943.682 2205.25,3708.246 2406.21,3978.157 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2643.832,4259.803 2643.832,3976.195 2360.225,4259.803 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2360.162,4259.797 2405.656,3979.863 2080.228,4214.304 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2080.166,4214.287 2169.975,3945.275 1811.154,4124.478 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1811.097,4124.454 1942.895,3873.333 1559.975,3992.656 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1559.921,3992.62 1730.296,3765.893 1333.194,3822.245 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1333.146,3822.204 1537.684,3625.742 1136.684,3617.666 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1136.645,3617.616 1370.049,3456.509 975.537,3384.212 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="975.505,3384.16 1231.73,3262.579 853.925,3127.935 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="853.903,3127.874 1126.313,3048.97 774.999,2855.465 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="774.986,2855.403 1056.525,2821.218 740.801,2573.864 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="740.798,2573.8 1024.175,2585.22 752.218,2290.423 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="752.225,2290.361 1030.101,2347.089 808.954,2012.485 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="808.972,2012.424 1074.149,2112.993 909.54,1747.247 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="909.567,1747.188 1155.178,1888.992 1051.371,1501.578 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1051.407,1501.526 1271.091,1680.892 1230.773,1281.843 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1230.817,1281.797 1418.883,1494.08 1443.1,1093.731 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1443.151,1093.692 1594.729,1333.394 1682.853,942.114 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1682.908,942.084 1794.073,1202.997 1943.821,830.92 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1943.881,830.9 2011.753,1106.266 2219.247,763.029 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2219.31,763.018 2242.131,1045.706 2501.997,740.198 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.061,740.198 2479.24,1022.885 2784.748,763.018 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2784.811,763.029 2716.939,1038.394 3060.177,830.9 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3060.237,830.92 2949.072,1091.833 3321.149,942.084 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3321.205,942.114 3169.627,1181.816 3560.907,1093.693 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3560.958,1093.73 3372.892,1306.013 3773.241,1281.797 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3773.285,1281.843 3553.602,1461.208 3952.651,1501.526 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3952.687,1501.578 3707.075,1643.381 4094.49,1747.189 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4094.518,1747.246 3829.341,1847.814 4195.086,2012.423 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4195.104,2012.484 3917.228,2069.213 4251.832,2290.359 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4251.84,2290.423 3968.463,2301.843 4263.26,2573.8 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4263.258,2573.863 3981.719,2539.678 4229.073,2855.402 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4229.059,2855.464 3956.649,2776.56 4150.154,3127.874 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4150.133,3127.934 3893.908,3006.354 4028.553,3384.158 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4028.52,3384.213 3795.116,3223.106 3867.413,3617.617 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3867.374,3617.666 3662.836,3421.205 3670.913,3822.204 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3670.864,3822.247 3500.489,3595.519 3444.136,3992.622 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3444.083,3992.655 3312.284,3741.533 3192.961,4124.454 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3192.902,4124.479 3103.092,3855.468 2923.89,4214.289 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2923.829,4214.304 2878.335,3934.369 2643.895,4259.797 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2365.982,4599.896 2360.672,4261.908 2703.97,4594.586 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2704.045,4594.579 2644.587,4261.82 3036.804,4535.122 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3036.878,4535.101 2924.812,4216.188 3355.791,4423.034 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3355.859,4423.005 3194.087,4126.199 3652.665,4261.233 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3652.728,4261.189 3445.44,3994.177 3919.741,4053.901 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3919.797,4053.851 3672.362,3823.547 4150.101,3806.415 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4150.147,3806.355 3868.972,3618.725 4337.776,3525.18 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4337.813,3525.117 4030.182,3385.021 4477.91,3217.485 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4477.935,3217.413 4151.814,3128.478 4566.869,2891.292 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4566.884,2891.218 4230.72,2855.749 4602.353,2555.054 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4602.354,2554.978 4264.854,2573.893 4583.439,2217.478 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4583.43,2217.404 4253.334,2290.212 4510.621,1887.308 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4510.599,1887.236 4196.457,2012.053 4385.782,1573.094 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4385.749,1573.025 4095.697,1746.617 4212.156,1282.973 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4212.112,1282.913 3953.662,1500.785 3994.24,1024.464 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3994.187,1024.41 3774.034,1280.919 3737.678,804.257 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3737.617,804.212 3561.461,1092.714 3449.115,628.056 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3449.048,628.022 3321.453,941.045 3136.025,500.427 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3135.953,500.404 3060.222,829.842 2806.515,424.674 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2806.44,424.663 2784.536,761.982 2469.121,402.758 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2469.045,402.759 2501.534,739.224 2132.58,435.248 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2132.506,435.262 2218.547,762.158 1805.61,521.303 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1805.539,521.328 1942.903,830.189 1496.678,658.692 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1496.611,658.729 1681.742,941.556 1213.785,843.86 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1213.725,843.906 1441.827,1093.373 964.258,1072.008 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="964.206,1072.063 1229.372,1281.709 754.56,1337.228 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="754.519,1337.291 1049.88,1501.687 590.123,1632.652 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="590.091,1632.721 907.998,1747.608 475.203,1950.628 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="475.184,1950.7 807.404,2013.105 412.78,2282.92 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="412.771,2282.996 750.699,2291.3 404.467,2620.924 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="404.471,2620.999 739.354,2574.988 450.481,2955.883 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="450.5,2955.957 773.666,2856.822 549.634,3279.123 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="549.66,3279.194 852.74,3129.504 699.35,3582.273 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="699.391,3582.338 974.533,3385.969 895.76,3857.48 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="895.806,3857.538 1135.886,3619.576 1133.768,4097.618 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1133.827,4097.667 1332.626,3824.276 1407.219,4296.466 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1407.284,4296.505 1559.653,3994.764 1709.025,4448.875 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1709.096,4448.904 1811.089,4126.629 2031.371,4550.898 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2031.444,4550.915 2080.42,4216.452 2365.907,4599.891 					" />
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <g>
                  <g>
                    <polygon points="2703.477,5000 2703.477,4597.104 2300.58,5000 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2300.491,4999.993 2365.12,4602.314 1902.812,4935.364 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1902.724,4935.34 2030.309,4553.179 1520.563,4807.756 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1520.481,4807.723 1707.716,4450.976 1163.734,4620.487 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1163.658,4620.437 1405.695,4298.345 841.565,4378.4 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="841.497,4378.341 1132.067,4099.245 562.401,4087.771 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="562.345,4087.701 893.922,3858.83 333.474,3756.124 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="333.428,3756.049 697.425,3583.331 160.71,3392.053 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="160.678,3391.967 547.667,3279.874 48.585,3004.978 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="48.567,3004.891 448.526,2956.327 0.003,2604.932 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="0,2604.841 402.569,2621.064 16.223,2202.272 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="16.233,2202.183 410.987,2282.773 96.823,1807.43 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="96.848,1807.343 473.563,1950.212 239.717,1430.629 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="239.756,1430.545 588.674,1631.993 441.204,1081.627 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="441.256,1081.554 753.341,1336.364 696.065,769.469 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="696.128,769.404 963.297,1070.976 997.7,502.235 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="997.772,502.18 1213.106,842.703 1338.296,286.845 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1338.375,286.803 1496.297,657.46 1709.031,128.882 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="1709.117,128.854 1805.536,520.042 2100.305,32.434 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2100.394,32.42 2132.814,434.009 2501.984,0 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2502.074,0 2469.654,401.59 2903.664,32.42 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="2903.753,32.434 2807.333,423.623 3294.942,128.853 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3295.026,128.882 3137.105,499.538 3665.683,286.803 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3665.762,286.846 3450.428,627.37 4006.286,502.18 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4006.359,502.234 3739.189,803.806 4307.931,769.403 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4307.994,769.469 3995.908,1024.278 4562.803,1081.554 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4562.853,1081.628 4213.935,1283.076 4764.301,1430.546 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4764.341,1430.627 4387.627,1573.496 4907.21,1807.342 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4907.234,1807.428 4512.48,1888.017 4987.824,2202.182 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4987.835,2202.272 4585.266,2218.495 5004.058,2604.841 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="5004.056,2604.931 4604.097,2556.367 4955.492,3004.889 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4955.472,3004.978 4568.482,2892.885 4843.379,3391.967 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4843.349,3392.051 4479.352,3219.333 4670.63,3756.048 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4670.583,3756.126 4339.006,3527.255 4441.712,4087.703 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4441.658,4087.772 4151.088,3808.676 4162.562,4378.342 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="4162.492,4378.402 3920.455,4056.31 3840.4,4620.439 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3840.323,4620.486 3653.088,4263.739 3483.576,4807.721 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3483.492,4807.758 3355.908,4425.596 3101.331,4935.342 					" />
                  </g>
                </g>
                <g>
                  <g>
                    <polygon points="3101.244,4935.363 3036.615,4537.685 2703.566,4999.993 					" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}
