import Footer from 'src/components/footer/Footer';

// ----------------------------------------------------------------------





// const RootStyle = styled('div')(({ theme }) => ({
//   position: 'relative',
//   backgroundColor: theme.palette.background.default
// }));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <Footer />
  );
}
