// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Accordion, TextField, Typography, AccordionSummary, AccordionDetails, Select, MenuItem, InputLabel, Grid, Divider, Card, } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { ExpandLessRounded } from '@material-ui/icons';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from 'src/components/charts';
//
// import BaseOptionChart from './BaseOptionChart';



// ----------------------------------------------------------------------




const RootStyle = styled('div')(({ theme }) => ({
    paddingTop: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(6)
    }
}));



// ----------------------------------------------------------------------

const CHART_DATA = [
    { name: 'series1', data: [31, 40, 28, 51, 42, 109, 100] },
    // { name: 'series2', data: [11, 32, 45, 32, 34, 52, 41] }
];

function ChartArea() {
    const chartOptions = merge(BaseOptionChart(), {
        xaxis: {
            type: 'string',
            categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ]
        },
        // yaxis:{},
        tooltip: { x: { format: 'dd/MM/yy HH:mm' } }
    });

    return <ReactApexChart type="area" series={CHART_DATA} options={chartOptions} height={320} />;
}


export default function PriceAnalysis() {
    const theme = useTheme()
    return (
        <Grid mt={3}>
            <Typography variant='h5' >Price Analysis</Typography>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Card sx={{ p: 2 }}>
                <ChartArea />
            </Card>
        </Grid>
    )
}